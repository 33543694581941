@import "../../styles/util";
.BlogDetailFooterSection {
    background-color: #F7707D;
    padding-top: 150px;
    position: relative;
    @include max(991.98) {
        padding: 100px 0;
        padding-bottom: 0;
    }
    @include max(767.98) {
        padding: 50px 0;
        padding-bottom: 0;
    }
    &::before {
        content: " ";
        position: absolute;
        width: 100%;
        background-image: url(../../assets/images/blogdetail/pinkwave.svg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
        bottom: 99%;
        left: 0;
        height: 150px;
        z-index: 6;
        @include max(1300) {
            content: none;
        }
    }
    .ContentWrap {
        padding-bottom: 150px;
        @include max(991.98) {
            padding-bottom: 50px;
        }
        h2 {
            font-size: size(40);
            color: #FFF8F9;
            font-weight: 700;
            line-height: 1.4;
        }
    }
    .ImgWrap {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 9;
        @include max(991.98) {
            position: relative;
        }
        .blogdetailfooterimg {}
    }
}