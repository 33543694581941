@import "../../../../styles/util";
.BlogBannerSection {
    background-color: #FFF8F9;
    padding: 250px 0;
    position: relative;
    z-index: 1;
    overflow: hidden;
    @include max(767.98) {
        padding: 50px 0;
    }
    h1 {
        font-size: size(40);
        color: #2E90D8;
        font-weight: 800;
        text-align: center;
        margin-bottom: 50px;
        line-height: 1.2;
    }
    p {
        color: #363131;
        font-size: size(16);
        margin-bottom: 20px;
        text-align: center;
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
        font-weight: 500;
        line-height: 1.3;
        +p {
            +p {
                margin-bottom: 0;
            }
        }
    }
    .colorImage1 {
        position: absolute;
        left: 7%;
        top: 100px;
        @include max(767.98) {
            display: none;
        }
    }
    .colorImage2 {
        position: absolute;
        right: 0;
        bottom: 100px;
        z-index: -1;
    }
    .colorImage3 {
        position: absolute;
        right: 7%;
        top: 100px;
        @include max(767.98) {
            top: 0;
        }
    }
    .colorImage4 {
        position: absolute;
        bottom: -25%;
        left: 0;
        @include max(767.98) {
            z-index: -1;
        }
    }
}