@import "../../../../styles/util";
.HomePageEnsureSection {
    background-color: #F5B85B;
    padding: size(130) 0;
    position: relative;
    overflow: visible;
    @include max(1300){
        padding-bottom: 0;
    }
    @include max(576.98){
        padding: 50px 0;
        padding-bottom: 0;
    }
    &::before {
        content: " ";
        position: absolute;
        width: 100%;
        background-image: url(../../../../assets/images/home/ensure/wave.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
        bottom: 99%;
        left: 0;
        height: 140px;
        z-index: 0;
        @include max(1300) {
            content: none;
        }
    }
    h2 {
        font-size: size(40);
        font-weight: 800;
        max-width: 908px;
        color: #FFFFFF;
        margin-bottom: 30px;
        @include max(576.98){
            font-size: size(25);
            margin-bottom: 20px;
        }
    }
    p {
        max-width: 664px;
        color: #FFFFFF;
        font-weight: 500;
        font-size: size(16);
        line-height: 1.5;
        margin-bottom: 40px;
        @include max(576.98){
            font-size: size(16);
            margin-bottom: 20px;
        }
    }
    .foatingImage {
        position: absolute;
        right:-90px;
        bottom: 0;
        width: 50%;
        @include max(1300){
            position: relative;
            right: 0;
            margin: auto;
        }
        @include max(767.98){
            margin-top: 50px;
        }
        figure{
            margin-bottom: 0;
            @include aspectRatio(621, 521);
        }
    }
}