@import "../../../../styles/util";
.LandingTrustSection {
    padding: 200px 0;
    background-color: #FFF8F9;
    position: relative;
    @include max(767.98) {
        padding: 50px 0;
        padding-bottom: 0;
    }
    .headWrap {
        display: flex;
        margin-bottom: 100px;
        @include max(991.98) {
            flex-wrap: wrap;
        }
        @include max(767.98) {
            margin-bottom: 30px;
        }
        .textWrap {
            h2 {
                font-size: size(40);
                font-weight: 800;
                line-height: 1.2;
                color: #FF8A3B;
                max-width: 784px;
                margin-bottom: 40px;
                @include max(767.98) {
                    font-size: size(25);
                    margin-bottom: 20px;
                }
            }
            p {
                font-size: size(18);
                font-weight: 500;
                color: #363131;
                max-width: 811px;
                line-height: 1.5;
                margin-bottom: 0;
                @include max(767.98) {
                    font-size: size(16);
                    line-height: 1.4;
                }
            }
        }
        .imageWrap {
            position: absolute;
            right: 10%;
            top: -25%;
            z-index: 2;
            @include max(1600) {
                right: 0;
                top: -10%;
                max-width: 500px;
                z-index: 2;
            }
            @include max(1300) {
                position: relative;
                top: 0;
                padding-top: 50px;
            }
            figure {
                margin-bottom: 0;
                // @include aspectRatio(781, 752);
            }
        }
    }
    .cardWrap {
        display: flex;
        flex-wrap: wrap;
        margin-left: -60px;
        margin-right: -60px;
        @include max(767.98) {
            padding-bottom: 20px;
        }
        .cards {
            padding: 0 60px;
            max-width: 33%;
            flex: 0 0 33%;
            margin-bottom: 50px;
            @include max(1200) {
                max-width: 50%;
                flex: 0 0 50%;
                // padding: 0 30px;
            }
            @include max(767.98) {
                max-width: 100%;
                flex: 0 0 100%;
                margin-bottom: 30px;
            }
        }
        .card {
            text-align: center;
            background-color: #FFFFFF;
            padding: 20px;
            border-radius: 25px;
            -webkit-box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.16);
            -moz-box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.16);
            box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.16);
            height: 100%;
            display: flex;
            flex-direction: column;
            @include max(1200) {
                margin-bottom: 50px;
            }
            @include max(767.98) {
                margin-bottom: 30px;
            }
            figure {
                margin-bottom: 0;
                img {
                    width: 100%;
                }
            }
            h4 {
                text-align: center;
                color: #2E90D8;
                font-size: size(30);
                font-weight: 700;
                line-height: 1.3;
                margin-bottom: 20px;
                margin-top: 40px;
                flex: 1 0;
                @include max(767.98) {
                    margin-top: 20px;
                    font-size: size(20);
                    margin-bottom: 10px;
                }
            }
            p {
                font-size: size(16);
                font-weight: 500;
                line-height: 1.5;
                max-width: 469px;
                margin-left: auto;
                margin-right: auto;
                flex: 1 0;
                @include max(767.98) {
                    font-size: size(16);
                    line-height: 1.4;
                }
            }
        }
    }
    .pinkStar {
        position: absolute;
        right: 35%;
        bottom: 7%;
        z-index: 1;
        @include max(1199.98) {
            right: 0;
            bottom: 0;
        }
        @include max(767.98) {
            max-width: 30px;
        }
    }
}