@import "../../../styles/util";
.AboutUsVisionWrapper {
    padding: size(50) 0;
    background-color: #FFF8F9;
    position: relative;
    z-index: 1;
    @include max(767.98) {
        padding: 50px 0;
    }
    &::before {
        content: " ";
        position: absolute;
        width: 100%;
        background-image: url(../../../assets/images/aboutus/vision/wave.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
        bottom: 99%;
        left: 0;
        height: 225px;
        z-index: 1;
        @include max(1300) {
            content: none;
        }
    }
    .custRow {
        align-items: center;
        .visionImgWrap{
            max-width: 60%;
            margin-right: auto;
            @include max(767.98){
                margin: auto;
            }
        }
        .missionImgWrap{
            max-width: 60%;
            margin-left: auto;
            @include max(767.98){
                margin: auto;
            }
        }
        figure {
            margin-bottom: 0;
            @include aspectRatio(848, 812);
            img{
                object-fit: contain;
            }
        }
        h2 {
            color: #2E90D8;
            font-size: size(40);
            font-weight: 800;
            margin-bottom: 20px;
            line-height: 1.2;
            @include max(767.98) {
                font-size: size(25);
            }
        }
        p {
            font-size: size(16);
            font-weight: 500;
            color: #363131;
            line-height: 1.3;
            max-width: 737px;
            margin-bottom: 0;
            @include max(767.98) {
                font-size: size(16);
                margin-bottom: 10px;
            }
        }
    }
    .aboutvisionstar {
        position: absolute;
        top: 5%;
        right: 10%;
        z-index: -1;
        @include max(767.98) {
            top: 2%;
            right: 2%;
            max-width: 30px;
        }
    }
    .aboutvisionvector {
        position: absolute;
        right: 0;
        bottom: 10%;
        z-index: -1;
        @include max(767.98) {
            bottom: 0;
            max-width: 50px;
        }
    }
}

.missionContentWrap{
    text-align: right;
    @include max(767.98){
        text-align: left;
    }
    p{
        margin-left: auto;
        @include max(767.98){
            margin-right: auto;
        }
    }
}