@import "../../../../styles/util";
.homePageBannerSection {
    background-color: #2E90D8;
    padding: 150px 0;
    position: relative;
    @include max(767.98) {
        padding: 50px 0;
    }
    .imageWrap {
        position: absolute;
        bottom: 80px;
        left: -15px;
        z-index: 1;
        @include max(1300) {
            bottom: 0;
        }
        @include max(767.98) {
            position: relative;
            left: 0;
            margin-bottom: 20px;
        }
        figure {
            z-index: 1;
            margin-bottom: 0;
            @include aspectRatio(806, 655);
        }
    }
    .textWrap {
        margin-left: auto;
        padding: 80px 0;
        @include max(1300) {
            padding: 0 15px;
        }
        h1 {
            font-size: size(45);
            font-weight: 700;
            color: #FFFFFF;
            max-width: size(630);
            margin-bottom: 30px;
            .outerSpan {
                overflow: hidden;
                display: block;
                .InnerSpan {
                    opacity: 0;
                    display: block;
                    position: relative;
                    animation: reveal 0.8s ease-in-out forwards;
                }
            }
            @include max(991.98) {
                font-size: size(30);
            }
            @include max(576.98) {
                font-size: size(25);
                margin-bottom: 30px;
            }
        }
        p {
            overflow: hidden;
            span {
                display: block;
                height: 100%;
                font-size: size(16);
                font-weight: 500;
                line-height: 1.5;
                color: #FFFFFF;
                max-width: size(550);
                margin-bottom: 30px;
                animation: reveal 0.8s ease-in-out forwards;
                @include max(576.98) {
                    font-size: size(18);
                    margin-bottom: 10px;
                    line-height: 1.6;
                }
            }
        }
        figure {
            margin-bottom: 0;
            text-align: right;
            padding-top: 90px;
            img{
                animation: zoom .3s linear;
            }
            @include max(991.98) {
                text-align: left;
                padding-top: 50px;
            }
            @include max(576.98) {
                padding-top: 25px;
                img {
                    max-width: 25%;
                }
            }
        }
    }
    .bannerBackgroundImg {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 0;
        @include max(767.98) {
            display: none;
        }
    }
    .bannerBackgroundImgGreen {
        position: absolute;
        right: -175px;
        bottom: 50px;
        z-index: 10;
        animation: zoom .3s linear;
        @include max(1400) {
            max-width: size(200);
            right: -120px;
        }
        @include max(576.98) {
            max-width: 25%;
            right: -50px;
        }
    }
}

@keyframes reveal {
    from {
        opacity: 0;
        transform: translateY(100%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}