@import "../../../../styles/util";
.ContactUsBanner {
    background-color: #2E90D8;
    padding: size(100) 0;
    padding-bottom: 0;
    @include max(767.98) {
        padding: size(50) 0;
    }
    .ImgWrap {
        display: flex;
        align-items: flex-end;
        @include max(991.98) {
            align-items: center;
        }
        figure {
            margin-bottom: 0;
        }
    }
    .ContentWrap {
        padding-top: 50px;
        padding-bottom: 400px;
        @include max(1600) {
            padding-bottom: 300px;
        }
        @include max(991.98) {
            padding-bottom: 50px;
        }
        h1 {
            font-size: size(45);
            font-weight: 700;
            color: #FFFFFF;
            margin-bottom: 25px;
            @include max(767.98) {
                font-size: size(25);
                margin-bottom: 20px;
            }
        }
        p {
            color: #FFF8F9;
            margin-bottom: 0;
            font-size: size(20);
            font-weight: 600;
            @include max(767.98) {
                font-size: size(16);
            }
        }
    }
}