.AutoPopupMain {
    .modalGroup {
        width: 100% !important;
        max-width: 700px !important;
    }

    .formContentWrapper {
        box-shadow: 0px 6px 6px 0px rgb(0 0 0 / 16%);
        padding: 20px;
        background-color: #FFFFFF;
        border-radius: 12px;

        .Main {
            padding: 20px 0px;
        }


        h3 {
            color: #363131;
            font-size: size(30);
            font-weight: 600;
        }

        h6 {
            color: #363131;
            font-size: size(12) !important;
            font-weight: 600;
            margin-bottom: 20px;
        }

        input {
            outline: none;
            margin-bottom: 15px;
            border-color: #CECECE;
            border-radius: 3px;
            padding: 10px;

            &:not(:placeholder-shown) {
                label {
                    transform: scale(0.5);

                }
            }
        }

        label {
            color: #000000;
            font-size: size(20);
            font-weight: 600;
        }

        textarea {
            outline: none;
            resize: none;
            height: 170px;

            &:focus {
                border-color: #CECECE;
            }

            margin-bottom: 50px;
        }

        .submitBtn {
            width: 100%;
        }
    }



}

.inputError,
.react-datepicker__input-container .inputError {
    border: 1px solid red !important;
}

.errorMessage {
    border: red;
    color: red;
    font-size: 11px;
    position: relative;
    top: -10px;
}