@import "../../../../styles/util";

.HomepagePartnerSection{
    padding: size(150) 0;
    background-color: #FFF8F9;
    position: relative;
    @include max(767.98){
        padding: 50px 0;
    }
    &::after {
        content: " ";
        position: absolute;
        width: 100%;
        background-image: url(../../../../assets/images/home/partner/wave-border.svg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
        top: 99%;
        left: 0;
        height: 230px;
        z-index: 5;
        @include max(1300) {
            content: none;
        }
    }
    .TextWrap{
        h2{
            color: #F7717D;
            font-size: size(40);
            font-weight: 800;
            margin-bottom: 20px;
            @include max(576.98){
                font-size: size(25);
            }
        }
        p{
            font-size: size(16);
            font-weight: 500;
            line-height: 1.5;
            margin-bottom: 70px;
            @include max(576.98){
                font-size: size(16);
                margin-bottom: 25px;
            }
        }
        .tabWrap{
            display: flex;
            align-items: center;
            margin-bottom: size(30);
            @include max(576.98){
                align-items: flex-start;
            }
            figure{
                margin-bottom: 0;
                max-width: 15%;
                flex: 0 0 15%;
                @include max(576.98){
                    max-width: 20%;
                    flex: 0 0 20%;
                }
            }
        }
        .contentWrap{
            margin-left: 40px;
            @include max(576.98){
                margin-left: 15px;
            }
            h3{
                color: #F5B85B;
                font-size: size(35);
                font-weight: 700;
                @include max(576.98){
                    font-size: size(20);
                }
            }
            p{
                font-size: size(16);
                font-weight: 500;
                line-height: 1.5;
                margin-bottom: 0;
                @include max(576.98){
                    font-size: size(16);
                }
            }
        }
    }
    .ImageWrap{
        display: flex;
        align-items: center;
        figure{
            margin-bottom: 0;
            right: -250px;
            @include aspectRatio(935, 935);
            @include max(1300){
                right: 0;
            }
        }
    }
}