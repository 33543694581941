@import "../../styles/util";
.card {
    position: relative;
    height: 100%;
    
    &_body {
        background-color: #FFFFFF;
        border-radius: 25px;
        padding: 65px;
        height: 100%;
        text-align: center;
        position: relative;
        -webkit-box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.16);
        -moz-box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.16);
        box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.16);
        z-index: 1;
        @include max(767.98) {
            padding: 30px;
        }
    }
    &::before {
        content: " ";
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 25px;
        border: 5px dashed #FF8A3B;
        top: -20px;
        left: -20px;
        z-index: 0;
    }
    h3 {
        color: #2E90D8;
        font-size: size(35);
        margin-bottom: 40px;
        font-weight: 700;
        @include max(767.98) {
            font-size: size(20);
            margin-bottom: 20px;
        }
    }
    p {
        font-size: size(17);
        font-weight: 500;
        margin-bottom: 0;
        line-height: 1.2;
        @include max(767.98) {
            font-size: size(16);
            line-height: 1.4;
        }
    }


    &_primary {
        &::before {
            border-color: #FF8A3B;
        }
        h3 {
            color: #2E90D8;
        }
    }
    &_secondary {
        .card_body{
                padding: 35px;
            }
        &::before {
            border-color: #2E90D8;
        }
        h3 {
            color: #F5B85B;
        }
    }
}