@import "../../../styles/util";
.PrivacyPolicySection {
    background-color: #FFF8F9;
    padding: 150px 0;
    @include max(991.98) {
        padding: 50px 0;
    }
    .PrivacyWrapper {
        max-width: 70%;
        margin-right: auto;
        @include max(991.98) {
            max-width: 100%;
        }
        h1 {
            color: #2E90D8;
            font-weight: 800;
            font-size: size(50);
            margin-bottom: 50px;
        }
        p {
            color: #363131;
            line-height: 2;
            margin-bottom: 30px;
            font-weight: 500;
            font-size: size(16);
            text-align: justify;
            span {
                color: #F5B85B;
            }
        }
        h5 {
            color: #F5B85B;
            font-weight: 600;
            font-size: size(25);
            margin-bottom: 30px;
        }
        ul,
        ol {
            li {
                color: #363131;
                line-height: 1.8;
                margin-bottom: 20px;
                font-weight: 500;
                font-size: size(16);
                text-align: justify;
                span {
                    display: inline-block;
                    font-weight: 700;
                    padding-right: 5px;
                }
            }
        }
    }
    a {
        text-decoration: none;
        margin-bottom: 30px;
    }
    b {
        line-height: 1.8;
    }
}