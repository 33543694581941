@import "../../../../styles/util";
.LandingServicesSection {
    padding: 100px 0;
    background-color: #FFF1DD;
    position: relative;
    z-index: 1;
    @include max(767.98) {
        padding: 50px 0;
    }
    &::before {
        content: " ";
        position: absolute;
        width: 100%;
        background-image: url(../../../../assets/images/landing/services/wave-border.svg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
        bottom: 99%;
        left: 0;
        height: 260px;
        @include max(1300) {
            content: none;
        }
    }
    &::after {
        content: " ";
        position: absolute;
        width: 100%;
        background-image: url(../../../../assets/images/landing/services/border-bottom.svg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
        top: 99%;
        left: 0;
        height: 155px;
        @include max(1300) {
            content: none;
        }
    }
    .textWrap {
        @include max(767.98) {
            padding-bottom: 50px;
        }
        figure {
            margin-bottom: 60px;
            @include max(767.98) {
                margin-bottom: 20px;
            }
            img {
                @include max(767.98) {
                    max-width: 30%;
                }
            }
        }
        h2 {
            color: #2E90D8;
            font-size: size(40);
            font-weight: 800;
            line-height: 1.4;
            max-width: size(1015);
            margin-bottom: 70px;
            @include max(767.98) {
                max-width: 100%;
                font-size: size(25);
                margin-bottom: 20px;
            }
        }
        .steps {
            display: flex;
            align-items: flex-start;
            margin-bottom: 70px;
            @include max(767.98) {
                margin-bottom: 20px;
            }
            &:last-child {
                margin-bottom: 0;
            }
            .imgWrap {
                figure {
                    margin-bottom: 0;
                    img {
                        @include max(767.98) {
                            max-width: 100%;
                        }
                    }
                }
            }
            .textWrap {
                margin-left: 40px;
                @include max(767.98) {
                    margin-left: 20px;
                    padding-bottom: 0;
                }
                h3 {
                    color: #F5B85B;
                    font-size: size(30);
                    font-weight: 700;
                    margin-bottom: 20px;
                    @include max(767.98) {
                        max-width: 100%;
                        font-size: size(20);
                        margin-bottom: 10px;
                    }
                }
                p {
                    font-size: size(18);
                    font-weight: 500;
                    line-height: 1.6;
                    color: #363131;
                    max-width: 577px;
                    margin-bottom: 0;
                    @include max(767.98) {
                        font-size: size(16);
                    }
                }
            }
        }
    }
    .imageWrap {
        figure {
            margin-bottom: 0;
        }
        .writingImage {
            @include aspectRatio(300, 200);
        }
    }
}

.blueStar {
    position: absolute;
    top: -4%;
    left: 25%;
    @include max(1300) {
        top: 3%;
        left: 50%;
        transform: translateX(-50%);
    }
    @include max(767.98) {
        top: 2%;
        left: 85%;
        max-width: 30px;
    }
}

.goldenStar {
    position: absolute;
    left: 3%;
    bottom: -4%;
    z-index: 1;
    @include max(1300) {
        right: 0;
        bottom: 0;
    }
    @include max(767.98) {
        max-width: 30px;
    }
}