@import './colors';
$primary-font: "Dax",
sans-serif,
sans-serif;
$secondary-font: 'Monument Extended',
sans-serif;
@mixin px-rem($pxValue, $property: "font-size", $standard-size: 18) {
    #{$property}: $pxValue/$standard-size + rem;
}

$base-size: 18;
@function size($target, $context: $base-size) {
    @return ($target / $context) * 1rem;
}

@mixin min-max($resMin, $resMax) {
    @media (min-width: $resMin+px) and (max-width: $resMax+px) {
        @content;
    }
}

@mixin max($res) {
    @media (max-width: $res+px) {
        @content;
    }
}

@mixin min($res) {
    @media (min-width: $res+px) {
        @content;
    }
}

@mixin lineClamp($lines) {
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@mixin aspectRatio($w, $h) {
    padding-bottom: calc(($h / $w) * 100%);
    position: relative;
    width: 100%;
    &>img,
    &>picture {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        img {
            width: 100%;
            height: 100%;
        }
    }
}

// %randomColor {
//   $colors: $red-color,
//   $yellow-color,
//   $orange-color,
//   $pink-color,
//   $blue-color,
//   $grey-color,
//   $black-color;
//   @for $i from 1 through length($colors) {
//     &:nth-child(#{length($colors)}n+#{$i}) {
//       background: nth($colors, $i);
//     }
//   }
// }
@mixin randomColor($parent, $child: "") {
    $colors: $red-color, $orange-color, $yellow-color, $pink-color, $red-color, $yellow-color, $blue-color, $grey-color, $black-color;
    @for $i from 1 through length($colors) {
        @if $child !="" {
            #{$parent}:nth-child(#{length($colors)}n+#{$i}) #{$child} {
                background: nth($colors, $i);
            }
        }
        @else {
            #{$parent}:nth-child(#{length($colors)}n+#{$i}) {
                background: nth($colors, $i);
            }
        }
    }
}

%ul {
    list-style: none !important;
    padding-left: 0;
    margin-bottom: 0;
    >li {
        position: relative;
        padding-left: 28px;
        &::before {
            content: '';
            display: inline-block;
            width: size(7);
            height: size(11);
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6.458' height='10.051' viewBox='0 0 6.458 10.051'%3E%3Cg id='Group_124718' data-name='Group 124718' transform='translate(0.472 0.586)'%3E%3Cpath id='Path_43174' data-name='Path 43174' d='M.472 9.465-.472 8.3l4.79-3.884L-.469.586.469-.586 5.982 3.827 5.985 5Z' transform='translate(0)' fill='red'/%3E%3C/g%3E%3C/svg%3E");
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            margin-right: size(15);
            left: 0;
            position: absolute;
            top: 0.3rem;
        }
        +li {
            margin-top: size(16);
        }
    }
}

@mixin randomColor1($parent,
$child: "") {
    $colors: $blue-color, $pink-color, $yellow-color, $orange-color, $black-color, $red-color, $grey-color, $orange-color, $yellow-color;
    @for $i from 1 through length($colors) {
        @if $child !="" {
            #{$parent}:nth-child(#{length($colors)}n+#{$i}) #{$child} {
                background: nth($colors, $i);
            }
        }
        @else {
            #{$parent}:nth-child(#{length($colors)}n+#{$i}) {
                background: nth($colors, $i);
            }
        }
    }
}

@mixin box-shadow($x-axis: 0,
$y-axis: 1px,
$blur: 2px,
$color: $default) {
    -webkit-box-shadow: $x-axis $y-axis $blur $color;
    -moz-box-shadow: $x-axis $y-axis $blur $color;
    box-shadow: $x-axis $y-axis $blur $color;
}

@-webkit-keyframes tabHover {
    0% {
        left: -100%;
    }
    70% {
        left: 0%;
    }
    100% {
        left: 100%;
    }
}

@-webkit-keyframes growBar1 {
    0% {
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        transform: scaleX(0.1);
    }
    36.6% {
        -webkit-animation-timing-function: cubic-bezier(0.33473, 0.12482, 0.78584, 1);
        animation-timing-function: cubic-bezier(0.33473, 0.12482, 0.78584, 1);
        transform: scaleX(0.1);
    }
    69.15% {
        -webkit-animation-timing-function: cubic-bezier(0.22573, 0, 0.23365, 1.37098);
        animation-timing-function: cubic-bezier(0.22573, 0, 0.23365, 1.37098);
        transform: scaleX(0.83);
    }
    100% {
        transform: scaleX(0.1);
    }
}

@keyframes growBar1 {
    0% {
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        transform: scaleX(0.1);
    }
    36.6% {
        -webkit-animation-timing-function: cubic-bezier(0.33473, 0.12482, 0.78584, 1);
        animation-timing-function: cubic-bezier(0.33473, 0.12482, 0.78584, 1);
        transform: scaleX(0.1);
    }
    69.15% {
        -webkit-animation-timing-function: cubic-bezier(0.22573, 0, 0.23365, 1.37098);
        animation-timing-function: cubic-bezier(0.22573, 0, 0.23365, 1.37098);
        transform: scaleX(0.83);
    }
    100% {
        transform: scaleX(0.1);
    }
}

@-webkit-keyframes moveBar1 {
    0% {
        left: -105.16667%;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
    }
    20% {
        left: -105.16667%;
        -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
        animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    }
    69.15% {
        left: 21.5%;
        -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
        animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    }
    100% {
        left: 95.44444%;
    }
}

@keyframes moveBar1 {
    0% {
        left: -105.16667%;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
    }
    20% {
        left: -105.16667%;
        -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
        animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    }
    69.15% {
        left: 21.5%;
        -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
        animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    }
    100% {
        left: 95.44444%;
    }
}

@-webkit-keyframes growBar2 {
    0% {
        -webkit-animation-timing-function: cubic-bezier(0.20503, 0.05705, 0.57661, 0.45397);
        animation-timing-function: cubic-bezier(0.20503, 0.05705, 0.57661, 0.45397);
        transform: scaleX(0.1);
    }
    19.15% {
        -webkit-animation-timing-function: cubic-bezier(0.15231, 0.19643, 0.64837, 1.00432);
        animation-timing-function: cubic-bezier(0.15231, 0.19643, 0.64837, 1.00432);
        transform: scaleX(0.57);
    }
    44.15% {
        -webkit-animation-timing-function: cubic-bezier(0.25776, -0.00316, 0.21176, 1.38179);
        animation-timing-function: cubic-bezier(0.25776, -0.00316, 0.21176, 1.38179);
        transform: scaleX(0.91);
    }
    100% {
        transform: scaleX(0.1);
    }
}

@keyframes growBar2 {
    0% {
        -webkit-animation-timing-function: cubic-bezier(0.20503, 0.05705, 0.57661, 0.45397);
        animation-timing-function: cubic-bezier(0.20503, 0.05705, 0.57661, 0.45397);
        transform: scaleX(0.1);
    }
    19.15% {
        -webkit-animation-timing-function: cubic-bezier(0.15231, 0.19643, 0.64837, 1.00432);
        animation-timing-function: cubic-bezier(0.15231, 0.19643, 0.64837, 1.00432);
        transform: scaleX(0.57);
    }
    44.15% {
        -webkit-animation-timing-function: cubic-bezier(0.25776, -0.00316, 0.21176, 1.38179);
        animation-timing-function: cubic-bezier(0.25776, -0.00316, 0.21176, 1.38179);
        transform: scaleX(0.91);
    }
    100% {
        transform: scaleX(0.1);
    }
}

@-webkit-keyframes moveBar2 {
    0% {
        left: -54.88889%;
        -webkit-animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40968);
        animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40968);
    }
    25% {
        left: -17.25%;
        -webkit-animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73372);
        animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73372);
    }
    48.35% {
        left: 29.5%;
        -webkit-animation-timing-function: cubic-bezier(0.4, 0.62703, 0.6, 0.90203);
        animation-timing-function: cubic-bezier(0.4, 0.62703, 0.6, 0.90203);
    }
    100% {
        left: 117.38889%;
    }
}

@keyframes moveBar2 {
    0% {
        left: -54.88889%;
        -webkit-animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40968);
        animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40968);
    }
    25% {
        left: -17.25%;
        -webkit-animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73372);
        animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73372);
    }
    48.35% {
        left: 29.5%;
        -webkit-animation-timing-function: cubic-bezier(0.4, 0.62703, 0.6, 0.90203);
        animation-timing-function: cubic-bezier(0.4, 0.62703, 0.6, 0.90203);
    }
    100% {
        left: 117.38889%;
    }
}

@-webkit-keyframes start {
    from {
        max-height: 0;
        opacity: 0;
    }
    to {
        max-height: 20px;
        opacity: 1;
    }
}

@keyframes start {
    from {
        max-height: 0;
        opacity: 0;
    }
    to {
        max-height: 20px;
        opacity: 1;
    }
}

@-webkit-keyframes end {
    from {
        max-height: 0;
        opacity: 0;
    }
    to {
        max-height: 2px;
        opacity: 1;
    }
}

@keyframes end {
    from {
        max-height: 0;
        opacity: 0;
    }
    to {
        max-height: 2px;
        opacity: 1;
    }
}

@-webkit-keyframes progressLinearMovement {
    0% {
        left: -100%;
    }
    50% {
        left: 100%;
    }
    100% {
        left: 100%;
    }
}

@keyframes progressLinearMovement {
    0% {
        left: -100%;
    }
    50% {
        left: 100%;
    }
    100% {
        left: 100%;
    }
}

@keyframes zoom{
    0%{
        transform: scale(0);
    }
    100%{
        transform: scale(1);
    }
}