@import "../../../../styles/util";
.LandingCareerSection {
    padding: 270px 0;
    padding-top: 50px;
    background-color: #E2F0FA;
    position: relative;
    @include max(767.98) {
        padding-bottom: 50px;
    }
    &::before {
        content: " ";
        position: absolute;
        width: 100%;
        background-image: url(../../../../assets/images/landing/career/wave-border.svg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
        bottom: 99%;
        left: 0;
        z-index: 1;
        height: 300px;
        @include max(1300) {
            content: none;
        }
    }
    .childcareHead {
        figure {
            margin-bottom: 0;
            text-align: right;
            img {
                @include max(767.98) {
                    max-width: 30%;
                }
            }
        }
        h2 {
            margin: 30px 0;
            display: flex;
            justify-content: center;
            line-height: 1.2;
            color: #2E90D8;
            font-size: size(40);
            font-weight: 700;
            @include max(767.98) {
                font-size: size(25);
                margin: 20px 0;
            }
        }
        p {
            font-size: size(18);
            font-weight: 500;
            display: flex;
            justify-content: center;
            line-height: 1.2;
            text-align: center;
            margin-bottom: 75px;
            @include max(767.98) {
                font-size: size(16);
                text-align: left;
                line-height: 1.4;
            }
        }
    }
    .cardWrap {
        margin-bottom: 60px;
        --bs-gutter-x: 60px;
        --bs-gutter-y: 60px;
        @include min(1200) {
            --bs-gutter-x: 117px;
            --bs-gutter-y: 60px;
        }
        @include max(767.98) {
            padding: 0 20px;
            padding-right: 15px;
        }
    }
    .pinkImage {
        position: absolute;
        left: 5%;
        top: -22%;
        z-index: 1;
        @include max(1600) {
            top: -18%;
        }
        @include max(1300) {
            top: 1%;
        }
        @include max(767.98) {
            top: 2%;
            max-width: 13%;
        }
    }
    .signupButtonWrapper {
        text-align: center;
    }
}