@import "../../../styles/util";
.AboutUsInspireSection {
    padding: size(50) 0;
    background-color: #55CECE;
    position: relative;
    @include max(767.98){
       padding-bottom: 0;
    }
    &::before {
        content: " ";
        position: absolute;
        width: 100%;
        background-image: url(../../../assets/images/aboutus/inspire/wave-top.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
        bottom: 99%;
        left: 0;
        height: 130px;
        z-index: 0;
        @include max(1300) {
            content: none;
        }
    }
    .contentWrap {
        h2 {
            font-size: size(40);
            font-weight: 700;
            max-width: 800px;
            line-height: 1.3;
            color: #FFFFFF;
            margin-bottom: 60px;
            @include max(767.98){
                font-size: size(25);
                margin-bottom: 20px;
            }
        }
    }
    .ImgWrap {
        position: absolute;
        right: 0;
        bottom: 0;
        figure {
            margin-bottom: 0;
        }
        @include max(1300){
            position: relative;
            @include max(767.98){
                margin-top: 30px;
            }
        }
    }
}