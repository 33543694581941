@import "../../styles/util";
.tabCard {
    height: 100%;
    padding: 0 20px;
    &_inner {
        .tabCardContent {
            &::before {
                display: none;
            }
        }
    }
    .tabCardContent {
        position: relative;
        z-index: 1;
        border-radius: 35px;
        height: 100%;
        -webkit-box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.16);
        -moz-box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.16);
        box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.16);
        &::before {
            content: " ";
            position: absolute;
            width: 100%;
            height: 100%;
            left: -35px;
            bottom: -35px;
            border: 5px dashed #2E90D8;
            border-radius: 35px;
            z-index: -1;
            @include max(767.98) {
                left: -20px;
                bottom: -20px;
            }
        }
        .tabCardBody {
            background-color: #FFFFFF;
            border-radius: 35px;
            height: 100%;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            figure {
                margin-bottom: 0;
                @include aspectRatio(484, 300);
                img {
                    width: 100%;
                    object-fit: cover;
                }
            }
            .cardText {
                padding: 30px;
                text-align: left;
                display: flex;
                flex-direction: column;
                height: 100%;
                @include max(767.98) {
                    padding: 20px;
                }
                h3 {
                    font-size: size(22);
                    line-height: 1.3;
                    color: #2E90D8;
                    font-weight: 700;
                    margin-bottom: 20px;
                    @include max(767.98) {
                        font-size: size(18);
                        margin-bottom: 15px;
                    }
                }
                p {
                    @include lineClamp(4);
                    color: #212529;
                    font-size: size(16);
                    font-weight: 400;
                    line-height: 1.6;
                    margin-bottom: 40px;
                    @include max(767.98) {
                        font-size: size(16);
                        margin-bottom: 15px;
                    }
                }
                a {
                    text-decoration: none;
                    margin-bottom: 0;
                    color: #F5B85B;
                    font-size: size(22);
                    font-weight: 700;
                    margin-top: auto;
                    @include max(767.98) {
                        font-size: size(16);
                    }
                }
            }
        }
    }
}