@import "../../../../styles/util";
.LandingBannerWrap {
    background-color: #2E90D8;
    padding: size(50) 0;
    position: relative;
    padding-bottom: 200px;
    @include max(1300){
        padding-bottom: 160px;
    }
    @include max(767.98) {
        padding: 50px 0;
    }
    .customRow {
        align-items: center;
        .textWrap {
            z-index: 1;
            @include max(1199.98) {
                padding-bottom: 50px;
            }
            p {
                overflow: hidden;
                margin-bottom: 0;
                span {
                    display: block;
                    color: #FFFFFF;
                    font-weight: 700;
                    font-size: size(20);
                    margin-bottom: size(24);
                    animation: reveal 0.8s ease-in-out forwards;
                    height: 100%;
                    @include max(767.98) {
                        font-size: size(16);
                    }
                }
            }
            h1 {
                overflow: hidden;
                text-transform: uppercase;
                span {
                    max-width: size(604);
                    font-size: size(45);
                    font-weight: 700;
                    line-height: 1.3;
                    color: #FFFFFF;
                    margin-bottom: size(25);
                    display: block;
                    animation: reveal 0.8s ease-in-out forwards;
                    @include max(576.98) {
                        font-size: size(25);
                    }
                }
            }
            .LearnMoreButton {
                font-size: 20px;
                font-weight: 500;
                border-radius: 9px;
                max-width: size(220);
                width: 100%;
                background-color: #FF8A3B;
                color: #FFFFFF;
                padding: 24px 0;
                display: flex;
                justify-content: center;
                text-decoration: none;
                line-height: 1;
                border: 1px solid #FF8A3B;
                transition: 0.2s all ease-in-out;
                &:hover {
                    color: #FF8A3B;
                    background-color: #FFFFFF;
                    @include max(1400) {
                        background-color: #FF8A3B;
                        color: #FFFFFF;
                    }
                }
                @include max(991.98) {
                    margin-bottom: 70px;
                    padding: 20px 0;
                }
            }
            .LandingBannerImage{
                position: absolute;
                bottom: 0;
                left: 0;
                max-width: 900px;
                max-height: 900px;
                @include max(1199.98){
                    position: static;
                    max-width: 100%;
                    padding-top: 50px;
                }
            }
        }
        .imageWrap {
            z-index: 1;
            position: relative;
            figure {
                margin-bottom: 0;
                @include aspectRatio(1089, 652);
                z-index: 1;
            }
        }
    }
    .pinkCircle {
        position: absolute;
        max-width: 300px;
        max-height: 300px;
        right: 40%;
        z-index: -1;
        top: 35%;
        width: 40%;
        animation: zoom .3s linear;
        // @keyframes zoom{
        //     0%{
        //         transform: scale(0);
        //     }
        //     100%{
        //         transform: scale(1);
        //     }
        // }
        @include max(991.98) {
            top: -17%;
        }
        @include max(767.98) {
            top: -20%;
            right: 15%;
        }
        @include max(375.98) {
            max-width: 100px;
        }
    }
    .greenCircle {
        position: absolute;
        max-width: 300px;
        max-height: 300px;
        right: 3%;
        bottom: 5%;
        animation: zoom .3s linear;
        width: 37%;
        @include max(1700) {
            right: -12%;
        }
        @include max(1300) {
            right: -15%;
        }
        @include max(991.98) {
            right: -25%;
        }
    }
    .waveimage {
        padding-bottom: 120px;
        @include max(767.98){
            padding-bottom: 30px;
            max-width: 25%;
        }
    }
    .yellowimage {
        position: absolute;
        bottom: 50px;
        left: 0;
        animation: zoom .3s linear;
        z-index: 1;
        @include max(1300) {
            bottom: -25px;
            z-index: 0;
        }
    }
}

@keyframes reveal {
    from {
        opacity: 0;
        transform: translateY(100%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}


.waveimagePositioned{
    position: absolute;
    top: 70%;
    left: 30%;
}