@import "../../../../styles/util";
.HomePagePilotSection {
    background-color: #FFF8F9;
    padding-bottom: 250px;
    position: relative;
    z-index: 1;
    @include max(767.98){
        padding-bottom: 50px;
    }
    .TextWrap {
        figure {
            margin-bottom: 70px;
            @include max(576.98){
               margin-bottom: 20px;
            }
            img{
                @include max(576.98){
                    max-width: 25%;
                }
            }
        }
        h2 {
            color: #2E90D8;
            font-size: size(40);
            font-weight: 800;
            line-height: 1.2;
            max-width: 1004px;
            margin-bottom: 50px;
            @include max(576.98){
                font-size: size(25);
                margin-bottom: 20px;
            }
        }
        h3 {
            color: #F5B85B;
            font-size: size(35);
            font-weight: 700;
            margin-bottom: 20px;
            max-width: 854px;
            line-height: 1.2;
            @include max(576.98){
                font-size: size(20);
            }
        }
        p {
            color: #363131;
            font-size: size(16);
            font-weight: 500;
            line-height: 1.9;
            max-width: 854px;
            margin-bottom: 0;
            @include max(576.98){
                font-size: size(16);
            }
        }
    }
    .ImageWrap {
        display: flex;
        align-items: center;
        figure {
            margin-bottom: 0;
        }
    }
    .BackgroundVector {
        position: absolute;
        bottom: 25%;
        right: 0;
        z-index: -1;
        margin-bottom: 0;
        @include max(576.98){
           max-width: 60px;
        }
    }
}