@import "../../../../styles/util";
.HomePageServiceSection {
    padding: size(200) 0;
    background-color: #FFF1DD;
    position: relative;
    @include max(767.98) {
        padding: 50px 0;
        padding-bottom: 0;
    }
    &::before {
        content: " ";
        position: absolute;
        width: 100%;
        background-image: url(../../../../assets/images/home/service/wave-top.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
        bottom: 99%;
        left: 0;
        height: 430px;
        z-index: 5;
        @include max(1300) {
            content: none;
        }
    }
    &::after {
        content: " ";
        position: absolute;
        width: 100%;
        background-image: url(../../../../assets/images/home/service/wave-bottom.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
        top: 99%;
        left: 0;
        height: 160px;
        z-index: 5;
        @include max(1300) {
            content: none;
        }
    }
    h2 {
        color: #2E90D8;
        text-align: center;
        font-size: size(40);
        font-weight: 700;
        margin-bottom: size(100);
        @include max(576.98) {
            font-size: size(25);
            margin-bottom: 40px;
        }
    }
    .cardWrap {
        justify-content: center;
        margin-bottom: 60px;
        --bs-gutter-x: 60px;
        --bs-gutter-y: 60px;
        @include min(1200) {
            --bs-gutter-x: 117px;
            --bs-gutter-y: 60px;
        }
        @include max(767.98) {
            padding: 0 20px;
            padding-right: 15px;
        }
    }
    .vectorBgWrapper {
        position: absolute;
        left: 0;
        bottom: -15%;
        z-index: 6;
        margin-bottom: 0;
        @include max(1300) {
            bottom: 0;
        }
        @include max(767.98) {
            display: none;
        }
    }
    .starBgWrapper {
        position: absolute;
        top: 0;
        right: 0;
        margin-bottom: 0;
        z-index: 5;
        @include max(576.98) {
            max-width: 30px;
        }
    }
}