@import "../../../../styles/util";
.BlogTabsSection {
    padding: 150px 0;
    background-color: #E2F0FA;
    position: relative;
    padding-bottom: 350px;
    @include max(767.98) {
        padding: 50px 0;
    }
    &::before {
        content: " ";
        position: absolute;
        width: 100%;
        background-image: url(../../../../assets/images/blog/blogtabs/borderwave.svg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
        bottom: 99%;
        left: 0;
        height: 190px;
        z-index: 1;
        @include max(1300) {
            content: none;
        }
    }
    // &::after {
    //     content: " ";
    //     position: absolute;
    //     width: 100%;
    //     background-image: url(../../../../assets/images/blog/blogtabs/borderbottomwave.svg);
    //     background-repeat: no-repeat;
    //     background-size: cover;
    //     background-position: bottom;
    //     top: 99%;
    //     left: 0;
    //     height: 190px;
    //     z-index: 1;
    //     @include max(1300) {
    //         content: none;
    //     }
    // }
     :global(.nav-tabs) {
        border: none;
        justify-content: center;
        margin-bottom: 80px;
        @include max(767.98) {
            margin-bottom: 30px;
        }
         :global(.nav-item) {
             :global(.nav-link) {
                background-color: transparent;
                border: none;
                outline: none;
                font-size: size(40);
                font-weight: 800;
                color: #2E90D8;
                margin-right: 60px;
                @include max(767.98) {
                    font-size: size(25);
                }
            }
             :global(.active) {
                color: #F5B85B !important;
                border-bottom: 1px solid #F5B85B;
            }
        }
    }
     :global(.tab-content) {
        .Tab1ContentWrap {
            justify-content: center;
            @include min(992) {
                --bs-gutter-x: 80px;
                --bs-gutter-y: 40px;
            }
            .tabCard {
                max-width: 33.3%;
                flex: 0 0 33.3%;
                padding: 0 40px;
                margin-bottom: 80px;
                @include max(1200) {
                    max-width: 50%;
                    flex: 0 0 50%;
                }
                @include max(767.98) {
                    max-width: 90%;
                    flex: 0 0 90%;
                    margin-left: auto;
                    margin-right: auto;
                }
                .tabCardContent {
                    position: relative;
                    z-index: 1;
                    border-radius: 35px;
                    height: 100%;
                    -webkit-box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.16);
                    -moz-box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.16);
                    box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.16);
                    &::before {
                        content: " ";
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: -35px;
                        bottom: -35px;
                        border: 5px dashed #2E90D8;
                        border-radius: 35px;
                        z-index: -1;
                        @include max(767.98) {
                            left: -20px;
                            bottom: -20px;
                        }
                    }
                    .tabCardBody {
                        background-color: #FFFFFF;
                        border-radius: 35px;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        figure {
                            margin-bottom: 0;
                        }
                        .cardText {
                            padding: 30px;
                            text-align: left;
                            display: flex;
                            flex-direction: column;
                            height: 100%;
                            h3 {
                                font-size: size(22);
                                line-height: 1.3;
                                color: #2E90D8;
                                font-weight: 700;
                                margin-bottom: 20px;
                                @include max(767.98) {
                                    font-size: size(18);
                                    margin-bottom: 15px;
                                }
                            }
                            p {
                                color: #212529;
                                font-size: size(20);
                                font-weight: 400;
                                line-height: 1.6;
                                margin-bottom: 40px;
                                @include max(767.98) {
                                    font-size: size(16);
                                    margin-bottom: 15px;
                                }
                            }
                            a {
                                text-decoration: none;
                                margin-bottom: 0;
                                color: #F5B85B;
                                font-size: size(22);
                                font-weight: 700;
                                margin-top: auto;
                                @include max(767.98) {
                                    font-size: size(16);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .blogtabredstar {
        position: absolute;
        right: 15%;
        top: 5%;
        @include max(767.98) {
            top: 0;
            right: 0;
            max-width: 30px;
        }
    }
    .blogtabreddot {
        position: absolute;
        left: 25%;
        bottom: 5%;
    }
}

.itemWrapper {
    margin-bottom: 50px;
}