@import "../../styles/util";
.BlogReplySection {
  background-color: #fff8f9;
  padding: 350px 0;
  @include max(1300.98) {
    padding: 100px 0;
  }
  @include max(767.98) {
    padding: 50px 0;
  }
  .replyWrapper {
    box-shadow: 0px 6px 20px 0px rgb(0 0 0 / 16%);
    border-radius: 35px;
    padding: 50px;
    @include max(767.98) {
      padding: 20px;
    }
    h6 {
      font-size: size(40);
      color: #2e90d8;
      font-weight: 700;
      margin-bottom: 30px;
      @include max(767.98) {
        font-size: size(20);
      }
    }
    :global(.form-floating) {
      :global(.form-control) {
        background-color: #fff8f9;
        outline: none;
        border: 1px solid #55cece;
        border-radius: 15px;
        resize: none;
      }
      .inputError,
      .react-datepicker__input-container .inputError {
        border: 1px solid red !important;
      }

      .errorMessage {
        border: red;
        color: red;
        font-size: 11px;
        position: relative;
        top: -3px;
      }
    }
    .inputWrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-left: -10px;
      margin-right: -10px;
      margin-top: 20px;
      :global(.form-floating) {
        max-width: 33.3%;
        flex: 0 0 33%;
        padding: 0 10px;
        margin-bottom: 20px;
        @include max(991.98) {
          max-width: 50%;
          flex: 0 0 50%;
        }
        @include max(767.98) {
          max-width: 100%;
          flex: 0 0 100%;
        }
        :global(.form-control) {
          outline: none;
          border: 1px solid #55cece;
          border-radius: 15px;
        }
      }
    }
  }
}

label {
  left: 13px !important;
  font-size: size(18);
  font-weight: 700;
  color: #2e90d8;
  @include max(767.98) {
    font-size: size(15);
  }
}
