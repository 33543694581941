@import "../../styles/util";
// .AutoPopupMain {
//     .modalGroup {
//         width: 100% !important;
//         max-width: 700px !important;
//     }

//     .formContentWrapper {
//         box-shadow: 0px 6px 6px 0px rgb(0 0 0 / 16%);
//         padding: 20px;
//         background-color: #FFFFFF;
//         border-radius: 12px;

//         .Main {
//             padding: 20px 0px;
//         }


//         h3 {
//             color: #363131;
//             font-size: size(30);
//             font-weight: 600;
//         }

//         h6 {
//             color: #363131;
//             font-size: size(12) !important;
//             font-weight: 600;
//             margin-bottom: 20px;
//         }

//         input {
//             outline: none;
//             margin-bottom: 15px;
//             border-color: #CECECE;
//             border-radius: 3px;
//             padding: 10px;

//             &:not(:placeholder-shown) {
//                 label {
//                     transform: scale(0.5);

//                 }
//             }
//         }

//         label {
//             color: #000000;
//             font-size: size(20);
//             font-weight: 600;
//         }

//         textarea {
//             outline: none;
//             resize: none;
//             height: 170px;

//             &:focus {
//                 border-color: #CECECE;
//             }

//             margin-bottom: 50px;
//         }

//         .submitBtn {
//             width: 100%;
//         }
//     }



// }

// .inputError,
// .react-datepicker__input-container .inputError {
//     border: 1px solid red !important;
// }

// .errorMessage {
//     border: red;
//     color: red;
//     font-size: 11px;
//     position: relative;
//     top: -10px;
// }

.LandingBannerFormWrapper{
    display: block;
    background-color: #FCFDFE;
    padding: 55px;
    border-radius: 30px;
    max-width: 800px;
    margin-left: auto;
    -webkit-box-shadow: 0px 6px 6px 0px rgba(0,0,0,0.16);
    -moz-box-shadow: 0px 6px 6px 0px rgba(0,0,0,0.16);
    box-shadow: 0px 6px 6px 0px rgba(0,0,0,0.16);
    @include max(1500){
        padding: 40px;
    }
    @include max(1199.98) {
    max-width: 100%;
    }
    @include max(767.98) {
        padding: 35px;
    }
    h5{
        margin-bottom: 15px;
        font-size: 32px;
        font-weight: 700;
        color: #363131;
        text-align: center;
        @include max(1500){
            font-size: 16px;
            margin-bottom: 5px;
        }
        @include max(767.98) {
            font-size: size(25);
            margin-bottom: 20px;
        }
    }
    p{
        margin-bottom: 30px;
        font-size: 30px;
        font-weight: 600;
        color: #363131;
        text-align: center;
        @include max(1500){
            font-size: 16px;
            margin-bottom: 10px;
        }
        @include max(767.98) {
            font-size: size(18);
            margin-bottom: 10px;
            line-height: 1.6;
        }
    }
    .Main{
        display: block;
        .InputColumn{
            margin-bottom: 35px;
            position: relative;
            // padding-left: 0;
            // padding-right: 0;
            @include max(1500){
                margin-bottom: 20px;
            }
            input{
                height: 60px;
                border: 1px solid #CECECE;
                border-radius: 9px;
                @include max(1500){
                    height: 40px;
                }
                &::placeholder{
                    color: #9D9D9D;
                    font-size: 18px;
                    font-weight: 500;
                    @include max(1500){
                        font-size: 11px;
                    }
                    @include max(767.98){
                        font-size: 12px;
                    }
                }
            }
            // label{
            //     position: absolute;
            //     top: 50%;
            //     transform: translateY(-50%);
            // }
        }
    }
}
.errorMessage{
    position: absolute;
    font-size: 12px;
    color: red;
    left: 13px;
}
.submitBtn{
    position: relative;
    text-align: center;
    button{
        padding-top: 8px;
        padding-bottom: 8px;
    }
}
.SumbitMessage{
    color: green;
    font-size: 12px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -20px;
}

.toastMessage{
    top: 7rem;
}