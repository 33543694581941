@import "../../../styles/util";
.AboutUsBannerSection {
    background-color: #2E90D8;
    padding: size(100) 0;
    padding-bottom: 0;
    position: relative;
    z-index: 1;
    overflow: hidden;
    @include max(767.98) {
        padding-top: 50px;
    }
    .textWrap {
        padding-top: 100px;
        position: relative;
        @include max(767.98){
            padding-top: 0;
        }
        h1 {
            color: #ffffff;
            font-size: size(45);
            font-weight: 800;
            margin-bottom: 20px;
            max-width: 650px;
            @include max(767.98) {
                font-size: size(25);
            }
        }
        p {
            color: #FFFFFF;
            font-size: size(20);
            font-weight: 500;
            margin-bottom: 0;
            line-height: 1.6;
            max-width: 690px;
            @include max(767.98) {
                font-size: size(16);
            }
        }
        .aboutbannerorangecircle {
            position: absolute;
            right: 0;
            bottom: 30%;
            z-index: -1;
        }
    }
    .ImageWrap {
        figure {
            margin-bottom: 0;
            @include aspectRatio(546, 540);
            @include max(767.98) {
                margin-top: 50px;
            }
        }
    }
    .aboutbannerhalfcircle {
        position: absolute;
        right: 0;
        bottom: 30%;
        z-index: -1;
    }
    .aboutbanneryellowbg {
        position: absolute;
        left: 0;
        bottom: -10%;
        z-index: -1;
    }
}