@import "../../../styles/util";
.AboutUsPilotSection {
    background-color: #FFF8F9;
    padding: size(300) 0;
    position: relative;
    z-index: 0;
    @include max(1300) {
        padding: 100px 0;
    }
    @include max(767.98) {
        padding: size(50) 0;
    }
    h2 {
        color: #2E90D8;
        font-size: size(40);
        font-weight: 800;
        text-align: center;
        margin-bottom: size(50);
        @include max(767.98) {
            font-size: size(25);
            margin-bottom: 20px;
        }
    }
    .pilotContentWrap {
        background-color: #FFFFFF;
        border-radius: 25px;
        padding: 60px;
        text-align: center;
        box-shadow: 0px 6px 6px 0px rgb(0 0 0 / 16%);
        @include max(767.98) {
            padding: size(15);
        }
        p {
            font-size: size(16);
            font-weight: 500;
            color: #363131;
            line-height: 1.6;
            max-width: 1319px;
            margin: auto;
            margin-bottom: size(30);
            @include max(767.98) {
                font-size: size(16);
                margin-bottom: 15px;
            }
        }
    }
    .aboutpilotcircle {
        position: absolute;
        left: 3%;
        top: 35%;
        z-index: -1;
    }
    .aboutpilotwave {
        position: absolute;
        right: 0;
        top: 20%;
        z-index: -1;
    }
    .aboutpilotfly {
        position: absolute;
        right: 0;
        bottom: -10%;
        max-width: 500px;
        @include max(1300) {
            max-width: 300px;
        }
        @include max(767.98) {
            display: none;
        }
    }
}