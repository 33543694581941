@import "../../../../styles/util";
.BlogInspireSection {
    background-color: #55CECE;
    padding: 150px 0;
    position: relative;
    @include max(1300) {
        padding-bottom: 0;
        padding-top: 100px;
    }
    @include max(767.98) {
        padding-bottom: 0;
        padding-top: 50px;
    }
    &::before {
        content: " ";
        position: absolute;
        width: 100%;
        background-image: url(../../../../assets/images/blog/bloginspire/borderwave.svg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
        bottom: 99%;
        left: 0;
        height: 190px;
        z-index: 1;
        @include max(1300) {
            content: none;
        }
    }
    .custRow {
        align-items: center;
    }
    .contentWrap {
        h2 {
            color: #FFFFFF;
            font-size: size(40);
            margin-bottom: 40px;
            font-weight: 700;
            @include max(767.98) {
                font-size: size(25);
            }
        }
    }
    .imageWrap {
        position: absolute;
        right: 100px;
        bottom: 0;
        z-index: 1;
        width: 50%;
        @include max(1500) {
            right: 0;
        }
        @include max(1300) {
            position: relative;
        }
        @include max(991.98) {
            margin-top: 50px;
            width: 100%;
        }
        figure {
            text-align: right;
            margin-bottom: 0;
        }
    }
}