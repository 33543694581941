@import "../../../styles/util";
.AboutUsStorySection {
    padding: size(150) 0;
    background-color: #EFF7FD;
    position: relative;
    z-index: 1;
    @include max(767.98) {
        padding: 50px 0;
    }
    &::before {
        content: " ";
        position: absolute;
        width: 100%;
        background-image: url(../../../assets/images/aboutus/story/wave-top.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
        bottom: 99%;
        left: 0;
        height: 151px;
        z-index: 0;
        @include max(1300) {
            content: none;
        }
    }
    &::after {
        content: " ";
        position: absolute;
        width: 100%;
        background-image: url(../../../assets/images/aboutus/story/wave-bottom.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
        top: 99%;
        left: 0;
        height: 300px;
        z-index: 0;
        @include max(1300) {
            content: none;
        }
    }
    h2 {
        color: #2E90D8;
        font-size: size(40);
        margin-bottom: 50px;
        text-align: center;
        font-weight: 800;
        @include max(767.98) {
            font-size: size(25);
            margin-bottom: 20px;
        }
    }
    .storyWrapper {
        background-color: #FFFFFF;
        border-radius: 25px;
        padding: size(40);
        -webkit-box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.16);
        -moz-box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.16);
        box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.16);
        margin-bottom: 60px;
        @include max(767.98) {
            padding: 15px;
            margin-bottom: 50px;
        }
        .storyContent {
            border: 5px dashed #55CECE;
            border-radius: 25px;
            padding: size(80);
            @include max(767.98) {
                padding: 15px;
            }
            p {
                font-size: size(16);
                font-weight: 500;
                color: #363131;
                text-align: center;
                margin-bottom: size(30);
                line-height: 1.4;
                max-width: 1163px;
                margin-left: auto;
                margin-right: auto;
                @include max(767.98) {
                    font-size: size(16);
                    margin-bottom: 15px;
                }
                +p {
                    +p {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    .imageRowWraop {
        display: flex;
        flex-wrap: wrap;
        margin-right: -60px;
        margin-left: -60px;
        position: absolute;
        z-index: 1;
        @include max(767.98) {
            margin-right: 0;
            margin-left: 0;
        }
        @include max(1300) {
            position: relative;
        }
        figure {
            margin-bottom: 0;
            padding: 0 size(60);
            max-width: 33%;
            flex: 0 0 33%;
            @include max(767.98) {
                padding: 0 size(20);
            }
        }
    }
    .aboutstoryyellow {
        left: 0;
        position: absolute;
        top: -25%;
        @include max(1400) {
            z-index: -1;
            left: 0;
            top: 2%;
            transform: none;
        }
        @include max(767.98) {
            max-width: 50px;
        }
    }
    .aboutstoryvector {
        position: absolute;
        left: 0;
        bottom: 15%;
        z-index: -1;
        @include max(767.98) {
            max-width: 50px;
        }
    }
    .aboutstorystar {
        position: absolute;
        top: 10%;
        right: 10%;
        z-index: -1;
        @include max(767.98) {
            max-width: 50px;
        }
    }
}