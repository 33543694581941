@import "../../../../styles/util";
.BlogDetailingSection {
    background-color: #E2F0FA;
    padding: 150px 0;
    padding-bottom: 250px;
    position: relative;
    z-index: 1;
    &::after {
        content: " ";
        position: absolute;
        width: 100%;
        background-image: url(../../../../assets/images/blogdetail/wavw-border.svg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
        top: 99%;
        left: 0;
        height: 360px;
        z-index: 1;
        @include max(1300) {
            content: none;
        }
    }
    @include max(991.98) {
        padding-bottom: 100px;
    }
    @include max(767.98) {
        padding: 50px 0;
    }
}

.BlogContentSection {}

.BlogDetailDataWrapper {
    background-color: #FFFFFF;
    border-radius: 90px;
    overflow: hidden;
    box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.16);
    @include max(767.98){
        border-radius: 30px;
    }
    figure{
        margin-bottom: 0;
        @include aspectRatio(1140, 600);
        img{
            object-fit: cover;
        }
    }
    h1 {
        padding: 60px;
        padding-bottom: 0;
        color: #F7707D;
        font-size: size(40);
        font-weight: 700;
        margin-bottom: 0;
        @include max(767.98){
            font-size: size(25);
            padding: 20px;
            padding-bottom: 0;
        }
    }
     :global(.admin-content-area) {
        padding: 60px;
        @include max(767.98){
            padding: 20px;
        }
        p {
            color: #212529;
            font-size: size(16);
            font-weight: 500;
            &:empty {
                display: none !important;
                visibility: hidden !important;
                line-height: 0 !important;
            }
            br {
                display: none;
            }
        }
        h2 {
            font-size: size(25);
            color: #2E90D8;
        }
        ul {
            li {
                color: #212529;
                font-size: size(16);
                font-weight: 500;
            }
        }
    }
}

.BlogSliderSection {
    .BlogSliderContainer {
        overflow: visible;
        position: relative;
        max-width: 480px;
        margin: auto;
        @include max(991.98) {
            margin-top: 50px;
        }
    }
     :global {
        .swiper {
            --swiper-navigation-size: 20px;
            padding-bottom: 60px;
            &-button-prev,
            &-button-next {
                top: initial;
                bottom: 0px;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background: #fff;
            }
            &-button-prev {
                left: auto;
                right: 70px;
            }
            &-button-next {}
        }
    }
}

:global(.swiper-button-prev) {
    background-color: transparent !important;
    border: 3px solid #2E90D8;
    color: #FF8A3B !important;
    font-weight: 800;
}

:global(.swiper-button-next) {
    background-color: transparent !important;
    border: 3px solid #2E90D8;
    color: #FF8A3B !important;
    font-weight: 800;
}

.blogdetalorangecircle {
    position: absolute;
    bottom: 5%;
    right: 20%;
    z-index: -1;
}

.blogdetailhalfcircle {
    position: absolute;
    bottom: 50%;
    transform: translateY(-50%);
    right: 0;
    z-index: -1;
}

.blogdetailorangestar {
    position: absolute;
    bottom: 50%;
    transform: translateY(-50%);
    right: 20%;
    z-index: -1;
}

.blogdetailorangewave {
    position: absolute;
    bottom: 5%;
    right: 0;
    z-index: -1;
}

.blogdetailredcircle {
    position: absolute;
    top: 1%;
    left: 3%;
    z-index: -1;
}