@import "../../../../styles/util";
.HomePageToolSection {
    background-color: #F5B85B;
    padding: size(100) 0;
    position: relative;
    z-index: 1;
    overflow: visible;
    padding-bottom: 250px;
    @include min(1921){
        padding-bottom: 370px;
    }
    @include max(1300) {
        content: none;
        padding-bottom: 100px;
    }
    @include max(576.98) {
        padding: 50px 0;
        padding-bottom: 50px;
    }
    &::before {
        content: " ";
        position: absolute;
        width: 100%;
        background-image: url(../../../../assets/images/home/tools/wave.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
        bottom: 99%;
        left: 0;
        height: 225px;
        z-index: 0;
        @include max(1300) {
            content: none;
        }
    }
    .ImageWrap {
        position: absolute;
        top: 40%;
        transform: translateY(-50%);
        left: 100px;
        figure {
            margin-bottom: 0;
            @include max(1300) {
                text-align: center;
                margin-bottom: 50px;
            }
        }
        @include max(1300) {
            position: relative;
            top: 0;
            left: 0;
            transform: none;
        }
    }
    .TextWrap {
        margin-left: auto;
        h2 {
            color: #FFFFFF;
            font-size: size(40);
            font-weight: 800;
            margin-bottom: 20px;
            @include max(576.98) {
                font-size: size(25);
            }
        }
        p {
            color: #FFFFFF;
            font-size: size(16);
            font-weight: 500;
            line-height: 1.4;
            margin-bottom: 0;
            max-width: size(800);
            @include max(576.98) {
                font-size: size(16);
            }
        }
    }
}
.floatRow{
    @include max(1300){
        align-items: center;
    }
}