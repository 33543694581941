@import "../../../../styles/util";
.LandingProceduresSection {
    background-color: #E2F0FA;
    padding: 100px 0;
    position: relative;
    @include max(767.98) {
        padding: 50px 0;
    }
    &::before {
        content: " ";
        position: absolute;
        width: 100%;
        background-image: url(../../../../assets/images/landing/procedures/wave-top.svg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
        bottom: 99%;
        left: 0;
        height: 200px;
        @include max(1300) {
            content: none;
        }
    }
    &::after {
        content: " ";
        position: absolute;
        width: 100%;
        background-image: url(../../../../assets/images/landing/procedures/wave-bottom.svg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
        top: 99%;
        left: 0;
        z-index: 1;
        height: 460px;
        @include max(1300) {
            content: none;
        }
    }
    figure {
        margin-bottom: 60px;
        text-align: right;
        @include max(767.98) {
            margin-bottom: 20px;
        }
        img {
            @include max(767.98) {
                max-width: 30%;
            }
        }
    }
    h2 {
        font-size: size(40);
        font-weight: 800;
        color: #2E90D8;
        text-align: center;
        margin-bottom: 40px;
        @include max(767.98) {
            font-size: size(25);
            text-align: left;
            margin-bottom: 20px;
        }
    }
    P {
        font-size: size(18);
        font-weight: 500;
        text-align: center;
        margin-bottom: 0;
        @include max(767.98) {
            font-size: size(16);
            line-height: 1.4;
            text-align: left;
        }
    }
    .procedureWrap {
        margin-top: 90px;
        display: flex;
        flex-wrap: wrap;
        margin-left: -25px;
        margin-right: -25px;
        @include max(767.98) {
            margin-top: 20px;
        }
        .procedure {
            padding: 0 25px;
            max-width: 20%;
            flex: 0 0 20%;
            margin-bottom: 80px;
            display: flex;
            flex-direction: column;
            &:last-child {
                @include max(576.98) {
                    margin-bottom: 30px;
                }
            }
            @include max(1600) {
                max-width: 25%;
                flex: 0 0 25%;
            }
            @include max(1200.98) {
                max-width: 33%;
                flex: 0 0 33%;
            }
            @include max(991.98) {
                max-width: 50%;
                flex: 0 0 50%;
            }
            @include max(576.98) {
                max-width: 90%;
                flex: 0 0 90%;
                margin-left: auto;
                margin-right: auto;
            }
            .procedureHead {
                padding: 15px;
                background-color: #6ED2D2;
                border-radius: 25px;
                min-height: 260px;
                display: flex;
                align-items: center;
                flex: 1 0;
                position: relative;
                &::before {
                    content: " ";
                    position: absolute;
                    background-image: url(../../../../assets/images/landing/procedures/path.png);
                    background-repeat: no-repeat;
                    background-size: contain;
                    left: -47px;
                    top: 50%;
                    height: 10px;
                    width: 50px;
                    @include max(1200) {
                        content: none;
                    }
                }
                p {
                    font-size: size(16);
                    font-weight: 600;
                    color: #FFFFFF;
                    line-height: 1.4;
                    margin-bottom: 0;
                    text-align: center;
                }
            }
            .procedureFooterBlue {
                width: 100%;
                height: 90px;
                background-color: #2E90D8;
                margin-top: 20px;
                position: relative;
                .procedureFooterEnd {
                    margin: auto;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    position: absolute;
                    width: 80%;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: -40%;
                    span {
                        height: 72px;
                        width: 72px;
                        display: block;
                        border-radius: 50%;
                        background-color: #FF8A3B;
                    }
                }
            }
            .procedureFooterOrange {
                width: 100%;
                height: 90px;
                background-color: #FF8A3B;
                margin-top: 20px;
                position: relative;
                .procedureFooterEnd {
                    margin: auto;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    position: absolute;
                    width: 80%;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: -40%;
                    span {
                        height: 72px;
                        width: 72px;
                        display: block;
                        border-radius: 50%;
                        background-color: #F5B85B;
                    }
                }
            }
        }
    }
    .pinkStar {
        position: absolute;
        bottom: 0;
        right: 0;
        max-width: 70px;
        z-index: 2;
        @include max(767.98) {
            max-width: 30px;
        }
    }
    .signupButtonWrapper {
        text-align: center;
        @include max(576.98) {
            margin-top: 50px;
        }
    }
}