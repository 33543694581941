@import "../../../../styles/util";

.HomePageApproachSection{
    background-color: #EFF7FD;
    padding: size(250) 0;
    @include max(767.98){
        padding: 50px 0;
        padding-bottom: 0;
    }
    .imgWrap{
        figure{
            @include aspectRatio(290, 240);
            margin-bottom: 0;
        }
        @include max(576.98){
            margin-bottom: 50px;
        }
    }
    .contentWrap{
        text-align: right;
        h2{
            font-size: size(40);
            font-weight: 800;
            margin-bottom: 20px;
            color: #2E90D8;
            @include max(576.98){
                font-size: size(25);
            }
        }
        p{
            font-size: size(16);
            font-weight: 500;
            color: #363131;
            margin-bottom: size(60);
            line-height: 1.4;
            @include max(576.98){
                font-size: size(16);
                margin-bottom: 20px;
            }
        }
        .tabWrap{
            display: flex;
            align-items: flex-start;
            margin-bottom: 40px;
            .tabContent{
                margin-right: 30px;
                @include max(576.98){
                    margin-right: 15px;
                }
                h3{
                    color: #F5B85B;
                    font-size: size(35);
                    margin-bottom: 20px;
                    font-weight: 700;
                    @include max(576.98){
                        font-size: size(20);
                        margin-bottom: 10px;
                    }
                }
                p{
                    font-size: size(16);
                    font-weight: 500;
                    color: #363131;
                    line-height: 1.5;
                    margin-bottom: 0;
                    @include max(576.98){
                        font-size: size(16);
                    }
                }
            }
            figure{
                margin-bottom: 0;
                max-width: 15%;
                flex: 0 0 15%;
            }
        }
    }
}