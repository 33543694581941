@import "./colors";
@import "./util";
$theme-colors: ( "primary": #FF8A3B, "secondary": #2E90D8, "yellow": #F5B85B, "light": #fff, );
$grid-breakpoints: ( xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1600px, );
$container-max-widths: ( sm: 540px, md: 720px, lg: 960px, xl: 1140px, xxl: 1636px, );
@import "~bootstrap/scss/bootstrap";
:root {
    --primary: #FF8A3B;
    --secondary: #2f255c;
    --secondary-light: #594f89;
    --red: #e30613;
    --yellow: #f47d07;
    --orange: #fd4f00;
    --pink: #e50159;
    --blue: #2f2552;
    --grey: #6f6f6f;
    --black: #000;
    --white: #fff;
    --light: #f5f5f5;
    --blue-alt: #1e2053;
    --hover-color: #e50059;
    --btn-grey: #c9c9c9;
    --btn-grey-transparent: #c9c9c926;
    --border-color: #d9d9d9;
    --light-grey: #ededed;
    --card-grey: #f5f5f5;
    --stroke: rgba(112, 112, 112, 0.15);
    --stroke-grey: #d9d9d9;
    --text-alt: #616161;
    --grey-alt: #cccccc;
}

// .theme-dark {
//   --primary: red;
//   --secondary: red;
// --card-grey: #171717;
// }
// .theme-color-blind {
//   --primary: red;
//   --secondary: red;
// }
html,
body {
    font-family: $primary-font;
    font-size: 16px;
    --bs-body-font-family: "Dax", sans-serif, sans-serif;
    @include min(1600) {
        font-size: 18px;
    }
}
:root{
    scroll-behavior: auto !important;
  }
body {
    @include max(1199.98) {
        // padding-bottom: 66px;
    }
}

* {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
}

p {
    font-size: 1rem;
    line-height: 28px;
    @include min(992) {
        line-height: size(32);
    }
    @include min(1200) {
        line-height: size(34);
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    // font-family: $secondary-font;
}

// titles
.h1 {
    font-family: $secondary-font;
    font-size: 28px;
    font-weight: 700;
    word-break: break-word;
    @include min(992) {
        font-size: size(45);
    }
    @include min(1200) {
        font-size: 3rem;
    }
    @include min(1600) {
        font-size: size(60);
    }
}

.h2 {
    font-size: 28px;
    @include min(992) {
        font-size: size(38);
    }
    @include min(1200) {
        font-size: size(45);
    }
}

.h3 {
    font-size: 25px;
    @include min(992) {
        font-size: size(35);
    }
    @include min(1200) {
        font-size: size(40);
        line-height: size(57);
    }
}

.h4 {
    font-size: 24px;
    @include min(992) {
        font-size: size(30);
    }
    @include min(1200) {
        font-size: size(35);
    }
}

.h5 {
    font-size: 22px;
    @include min(992) {
        font-size: size(30);
    }
}

.h6 {
    font-size: 18px;
    @include min(992) {
        font-size: size(25);
    }
}

main {
    overflow: hidden;
    min-height: 100vh;
    padding-top: 100px;
    @include min(992) {
        padding-top: 100px;
    }
    @include max(767.98) {
        padding-top: 80px;
    }
    @include min(1200) {
        padding-top: 100px;
    }
    @include min(1600) {
        // padding-top: 120px;
        padding-top: 100px;
        overflow: hidden;
    }
}

section {
    @include max(1300) {
        overflow: hidden;
    }
}

.title {
    font-family: $secondary-font;
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 24px;
    @include min(992) {
        font-size: size(45);
    }
    @include min(1200) {
        font-size: size(60);
        line-height: size(72);
        margin-bottom: size(16);
    }
    &-sm {
        font-size: 26px;
        @include min(992) {
            font-size: 1.8rem;
        }
        @include min(1200) {
            font-size: size(45);
            line-height: size(55);
            margin-bottom: 30px;
        }
    }
    &_sub {
        text-transform: uppercase;
        line-height: 1;
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 12px;
        @include min(1200) {
            margin-bottom: size(22);
            font-size: size(20);
        }
    }
    &-white {
        color: $white-color;
    }
}

.paragraph {
    font-family: $primary-font;
    font-size: size(16);
    font-weight: 400;
    line-height: 1.5;
    color: $white-color;
    margin-bottom: 40px;
    @include max(1199.98) {
        margin-bottom: 30px;
    }
}

.container-fluid {
    @include min(1200) {
        padding-left: size(50);
        padding-right: size(50);
    }
}

.form-control:focus {
    outline: 0;
    box-shadow: none;
}

.btn {
    font-weight: 500;
    font-size: 18px;
    padding: 16px 23px;
    min-width: 164px;
    @include min(1600) {
        min-width: 185px;
        font-size: size(20);
        padding: 20px 23px;
    }
    @include max(767.98) {
        padding: 10px 23px;
        font-size: size(18);
    }
    &-primary {
        color: #fff;
        &:hover {
            background-color: #fd9f5f;
            color: #fff !important;
        }
    }
    &-yellow {
        color: #000;
        &:hover {
            background-color: #f1c583;
        }
    }
    &-secondary {
        color: #fff;
        &:hover {
            background-color: #5f91b7;
            color: #fff !important;
        }
    }
}

// section {
//   overflow-x: hidden;
// }
.accordion-view {
    .accordion {
        &-button {
            font-weight: bold;
            padding: size(30) size(30) size(30) 0;
            &::after,
            &::before {
                content: "";
                display: block;
                background-image: none !important;
                background: $pink-color;
                position: absolute;
                top: 50%;
                transform: rotate(0deg) translateY(-50%);
                transition: all cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s;
            }
            &::after {
                width: size(16);
                height: 2px;
                right: 0;
            }
            &::before {
                height: size(16);
                width: 2px;
                right: size(7);
            }
            &:not(.collapsed) {
                color: $black-color;
                box-shadow: none;
                &::after {
                    transform: rotate(0deg) translateY(-50%);
                }
                &::before {
                    height: 0;
                }
            }
        }
        &-body {
            padding: 0 size(30) size(30) 0;
            color: $black-color;
            font-size: size(18);
            @include min(768) {
                font-size: size(16);
            }
            &>* {
                color: $black-color;
                font-size: size(18);
                @include min(768) {
                    font-size: size(16);
                }
            }
            p {
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }
    &-lg {
        .accordion {
            &-button {
                font-weight: bold;
                padding: 30px 40px 30px 0;
                @include min(992) {
                    padding: size(30) size(55) size(30) size(15);
                }
                @include min(1200) {
                    padding: size(30) size(67) size(30) size(15);
                }
                &::after {
                    width: size(16);
                    height: 2px;
                    right: size(15);
                    @include min(992) {
                        right: size(30);
                    }
                    @include min(1200) {
                        right: size(40);
                    }
                    @include min(1600) {
                        right: size(50);
                    }
                }
                &::before {
                    height: size(16);
                    width: 2px;
                    right: size(22);
                    @include min(992) {
                        right: size(37);
                    }
                    @include min(1200) {
                        right: size(47);
                    }
                    @include min(1600) {
                        right: size(57);
                    }
                }
                &:not(.collapsed) {
                    &::before {
                        height: 0;
                    }
                }
            }
            &-body {
                padding: 0 size(30) size(40) size(15);
                font-size: size(16);
                color: $black-color;
                &>* {
                    font-size: size(16);
                    color: $black-color;
                }
            }
        }
    }
    &-dark {
        .accordion {
            &-button {
                color: $white-color;
                &::after,
                &::before {
                    background: $white-color;
                }
                &:not(.collapsed) {
                    color: $primary-color;
                }
            }
            &-body {
                color: $white-color;
                &>* {
                    color: $white-color;
                }
            }
        }
    }
}

.table {
    table {
        @include max(1199.98) {
            min-width: 700px;
        }
        h2,
        h3,
        h4,
        h5,
        h6 {
            a {
                text-decoration: none;
                color: $black-color;
            }
        }
    }
}

.swiper-scroll {
    padding-bottom: 33px !important;
}

.accordion {
    // border-top: 1px solid $border-color;
    &-item {
        border: none;
        border-radius: 0 !important;
        border-bottom: 1px solid $border-color;
        // -webkit-box-shadow: inset 0px -1px 0px 0px $border-color;
        // -moz-box-shadow: inset 0px -1px 0px 0px $border-color;
        // box-shadow: inset 0px -1px 0px 0px $border-color;
        background-color: transparent;
    }
    &-button {
        color: $black-color;
        border-radius: 0 !important;
        background-color: transparent;
        box-shadow: none;
        font-size: 18px;
        padding: 20px 0 20px;
        &::after {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.777' height='9.392' viewBox='0 0 14.777 9.392'%3E%3Cg id='arrow-down-white' transform='translate(14.192 0.472) rotate(90)'%3E%3Cpath id='Path_43174' data-name='Path 43174' d='M.472 14.192l-.945-1.165L7.251 6.764-.469.586.469-.586l8.447 6.76 0 1.168Z' transform='translate(0 0)'/%3E%3C/g%3E%3C/svg%3E");
            background-size: contain;
            background-position: center;
            transform: rotate(0deg) scale(1);
            width: 14px;
            height: 14px;
            @include min(992) {
                width: size(15);
                height: size(15);
            }
            @include min(1200) {
                width: size(20);
                height: size(20);
            }
        }
        &:focus,
        &:active {
            outline: none;
            border-color: transparent;
            box-shadow: none;
        }
        @include min(1200) {
            font-size: size(22);
        }
        &:not(.collapsed) {
            color: $black-color;
            background-color: transparent;
            box-shadow: none;
            &::after {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.777' height='9.392' viewBox='0 0 14.777 9.392'%3E%3Cg id='arrow-down-white' transform='translate(14.192 0.472) rotate(90)'%3E%3Cpath id='Path_43174' data-name='Path 43174' d='M.472 14.192l-.945-1.165L7.251 6.764-.469.586.469-.586l8.447 6.76 0 1.168Z' transform='translate(0 0)'/%3E%3C/g%3E%3C/svg%3E");
                transform: rotate(0deg) scale(-1);
            }
        }
    }
    &-body {
        padding: 0 size(15) size(15);
        font-size: size(16);
        color: $black-color;
        &>* {
            font-size: size(16);
            color: $black-color;
        }
    }
    &-dark {
        --border-color: rgba(255, 255, 255, 0.2);
        .accordion {
            &-item {
                border: none;
                border-radius: 0 !important;
            }
            &-button {
                color: $white-color;
                border-radius: 0 !important;
                &::after {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.777' height='9.392' viewBox='0 0 14.777 9.392'%3E%3Cg id='arrow-down-white' transform='translate(14.192 0.472) rotate(90)'%3E%3Cpath id='Path_43174' data-name='Path 43174' d='M.472 14.192l-.945-1.165L7.251 6.764-.469.586.469-.586l8.447 6.76 0 1.168Z' transform='translate(0 0)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E");
                    background-size: contain;
                }
                &:not(.collapsed) {
                    color: $white-color;
                    &::after {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.777' height='9.392' viewBox='0 0 14.777 9.392'%3E%3Cg id='arrow-down-white' transform='translate(14.192 0.472) rotate(90)'%3E%3Cpath id='Path_43174' data-name='Path 43174' d='M.472 14.192l-.945-1.165L7.251 6.764-.469.586.469-.586l8.447 6.76 0 1.168Z' transform='translate(0 0)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E");
                    }
                }
            }
            &-body {
                color: $white-color;
                &>* {
                    color: $white-color;
                }
            }
        }
    }
}

.swiper {
    .swiper {
        &-scrollbar {
            border-radius: 0;
            background: rgba(0, 0, 0, 0.2);
        }
        &-scrollbar-drag {
            border-radius: 0;
            background: $black-color;
        }
    }
    &-button {
        &-disabled {
            pointer-events: none;
        }
    }
    &.swiper-horizontal {
        >.swiper {
            &-scrollbar {
                height: 2px;
                @include min(992) {
                    height: 5px;
                }
                &.swiper-scrollbar {
                    &-horizontal {
                        height: 2px;
                        left: 0.75rem;
                        width: calc(100% - 1.5rem);
                        overflow: hidden;
                        @include min(992) {
                            height: 5px;
                        }
                    }
                }
            }
        }
    }
}

@include min(1200) {
     ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }
     ::-webkit-scrollbar-thumb {
        border-radius: 0px;
        background: #ccc;
    }
     ::-webkit-scrollbar-track {
        background-color: #ebebeb;
        border-radius: 0px;
    }
}

.btn-check:focus+.btn,
.btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.bg {
    &-light {
        background-color: $light-color !important;
    }
    &-lg {
        @include min(992) {
            &-light {
                background-color: $light-color;
            }
        }
    }
}

.btn_see_more {
    font-size: 14px;
    min-width: initial;
    padding: 5px 14px;
    display: inline-flex;
    align-items: center;
    svg {
        margin-left: 10px;
    }
}

.header-nav {
    border-bottom: 2px solid transparent;
    .navbar-nav {
        align-items: center;
    }
}

.show-menu {
    overflow: hidden;
    @include min(1200) {
        padding-right: 5px;
    }
    .header-nav {
        border-color: rgb(255 255 255 / 13%);
        .navbar-nav {
            .nav-link {
                visibility: hidden;
            }
            .btn {
                visibility: hidden;
            }
        }
    }
}

.figure_sample {
    .image_wrap {
        width: 400px;
        max-width: 100%;
        position: relative;
        &::before {
            content: "";
            display: block;
            padding-bottom: calc((410 / 615) * 100%);
        }
        &>img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 1;
        }
    }
}

img {
    max-width: 100%;
}

.swiper_navs {
    &_prev,
    &_next {
        pointer-events: all;
        cursor: pointer;
        background-color: $white-color;
        width: 70px;
        height: 70px;
        border-radius: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.14);
        margin: 3px;
    }
     :global(.swiper-button-disabled) {
        opacity: 0;
        pointer-events: none;
    }
    @include max(1199.98) {
        display: none;
    }
}

.no_space {
    padding: 0 !important;
    &_bottom {
        padding-bottom: 0 !important;
    }
    &_top {
        padding-top: 0 !important;
    }
    &_left {
        padding-left: 0 !important;
    }
    &_right {
        padding-right: 0 !important;
    }
}

.container-fluid,
.container {
    .container-fluid,
    .container {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.short_container {
    @include min(1200) {
        max-width: 1140px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}


/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/* Firefox */

input[type="number"] {
    -moz-appearance: textfield;
}

.btn {
    font-size: size(16);
    outline: none !important;
    box-shadow: none !important;
    &:focus,
    &:focus-visible {
        outline: none !important;
        box-shadow: none !important;
    }
    &-full-width {
        @include max(575.98) {
            width: 100%;
        }
    }
}

// .offcanvas-backdrop {
//     @include max(991.98) {
//         display: none;
//     }
// }

body {
    @include max(991.98) {
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }
}

a {
    cursor: pointer;
    color: $primary-color;
    &:hover {
        color: $primary-color;
    }
}


/* Admin Content  */

.admin-content-area {
  

    /* =====     Unordered List Items     ===== */
    .table {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        padding: size(7);
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: 600;
        // font-family: $secondary-font;
        // display: inline-block;
        word-break: break-word;
    }
    a {
        color: $primary-color;
        transition: all ease-in-out 0.2s;
        &.btn {
            color: $white-color;
            margin: 5px 0 0;
        }
    }
    // titles
    .h1,
    h1 {
        font-size: 28px;
        font-weight: 700;
        word-break: break-word;
        @include min(992) {
            font-size: size(45);
        }
        @include min(1200) {
            font-size: 3rem;
        }
        @include min(1600) {
            font-size: size(60);
        }
    }
    .h2,
    h2 {
        font-size: 20px;
        margin-bottom: 20px;
        @include min(992) {
            font-size: size(28);
        }
        @include min(1200) {
            font-size: size(35);
        }
    }
    .h3,
    h3 {
        font-size: 18px;
        line-height: 1.3;
        @include min(992) {
            font-size: size(22);
        }
        @include min(1200) {
            font-size: size(27);
        }
    }
    .h4,
    h4 {
        font-size: 18px;
        @include min(992) {
            font-size: size(19);
        }
        @include min(1200) {
            font-size: size(23);
        }
    }
    .h5,
    h5 {
        font-size: 17px;
        @include min(992) {
            font-size: size(18);
        }
        @include min(1200) {
            font-size: size(22);
        }
    }
    .h6,
    h6 {
        font-size: 16px;
        @include min(992) {
            font-size: size(19);
        }
        @include min(1200) {
            font-size: size(20);
        }
    }
    ul,
    ol,
    li {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    p {
        &+h1,
        &+h2,
        &+h3,
        &+h4,
        &+h5,
        &+h6,
        &+ul,
        &+ol {
            margin-top: 35px;
        }
    }
    ol ul li {
        &::before {
            content: "" !important;
        }
    }
    ul {
        display: block;
        margin: 24px 0 20px;
        // padding-left: 20px;
        li {
            position: relative;
            padding-left: 28px;
            &::before {
                content: "" !important;
                display: inline-block;
                width: size(7);
                height: size(11);
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6.458' height='10.051' viewBox='0 0 6.458 10.051'%3E%3Cg id='Group_124718' data-name='Group 124718' transform='translate(0.472 0.586)'%3E%3Cpath id='Path_43174' data-name='Path 43174' d='M.472 9.465-.472 8.3l4.79-3.884L-.469.586.469-.586 5.982 3.827 5.985 5Z' transform='translate(0)' fill='red'/%3E%3C/g%3E%3C/svg%3E");
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                margin-right: size(15);
                left: 0;
                position: absolute;
                top: 0.4rem;
            }
            +li {
                margin-top: size(16);
            }
            a {
                &:hover {
                    color: $black-color;
                }
            }
        }
        &.white {
            li {
                &:before {
                    border-color: $white;
                }
            }
        }
    }
    /* =====     Ordered List Items     ===== */
    ol {
        counter-reset: item;
        margin: 24px 0 10px;
        padding-left: 20px;
        li {
            display: block;
            line-height: 1.4;
            padding-left: 40px;
            position: relative;
            &+li {
                margin-top: 15px;
            }
            &:before {
                content: counters(item, ".") ".";
                counter-increment: item;
                display: inline-block;
                left: 0;
                position: absolute;
            }
        }
        &.lower-alpha {
            li {
                &:before {
                    content: counters(item, "", lower-alpha) ")";
                }
            }
        }
        &.lower-roman {
            li {
                &:before {
                    content: counters(item, "", lower-roman) ")";
                }
            }
        }
        &.roman-sub {
            li {
                padding-left: 30px;
                &:before {
                    content: counters(item, ".", upper-roman) ".";
                    counter-increment: item;
                    font-weight: 500;
                }
                ol {
                    li {
                        padding-left: 32px;
                    }
                }
            }
        }
        &.decimal-sub {
            li {
                padding-left: 30px;
                &:before {
                    content: counters(item, ". ", decimal) ".";
                    counter-increment: item;
                    font-weight: 500;
                }
                ol {
                    padding-top: 5px;
                    li {
                        padding-left: 32px;
                    }
                    ol {
                        li {
                            padding-left: 50px;
                        }
                    }
                }
            }
        }
    }
    ul,
    ol {
        &+h2,
        &+h3,
        &+h4,
        &+h5,
        &+h6 {
            margin-top: 30px;
        }
        &+p {
            margin-top: 30px !important;
        }
    }
    iframe {
        margin-bottom: 20px;
        max-width: 100% !important;
        @include min(992) {
            margin-bottom: size(30);
        }
    }
    table {
        width: 100%;
        max-width: 100%;
        margin-top: size(25);
        margin-bottom: size(25);
        // border: 1px solid $light-color;
        -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.05);
        -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.05);
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.05);
        border-right: 1px solid #d3d1d1;
        border-bottom: 1px solid #d3d1d1;
        tr {
            border-bottom-width: 0;
            &:nth-child(odd) {
                td {
                    background-color: $light-color;
                    border-color: $border-color;
                    vertical-align: center;
                }
                th {
                    background-color: rgba(48, 38, 92, 0.941);
                }
            }
        }
        th,
        td {
            padding: size(15) size(15);
            vertical-align: top;
            border-top: none;
            border: 1px solid #d3d1d1;
            border-color: #d3d1d1 !important;
            border-bottom: none;
            vertical-align: middle;
            &>* {
                margin: 0;
            }
            &:last-child {
                border-right: none;
            }
        }
        td {
            background-color: $white-color;
        }
        th {
            vertical-align: bottom;
            border-bottom: 1px solid $black-color;
            background-color: $secondary-color;
            color: $white-color;
            border-color: #3e3e3e !important;
            border: none !important;
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                margin: 0;
                color: $white-color;
            }
        }
        table {
            background-color: $white-color;
        }
        tbody+tbody {
            border-top: 2px solid $light-color;
        }
    }
}

// End reset style
.gallery {
    --bs-gutter-y: 25px;
    margin-bottom: 30px;
    &>div {
        height: 180px;
        overflow: hidden;
        img {
            transition: 0.3s all ease;
            transform: scale(1);
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
        &:hover {
            img {
                transform: scale(1.03);
            }
        }
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    -webkit-text-fill-color: rgb(15, 15, 15);
    -webkit-box-shadow: 0 0 0px 1000px $white-color inset;
    transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill~button,
input:-webkit-autofill:hover~button,
input:-webkit-autofill:focus~button {
    background-color: $primary-color;
    color: $white-color;
}

.content-formsec {
    padding: 40px 0;
    background-color: $light-color;
    @include min(1200) {
        padding: size(80) 0;
    }
}

.mdx-table {
    table {
        width: 100%;
        max-width: 100%;
        margin-top: size(25);
        margin-bottom: size(25);
        // border: 1px solid $light-color;
        -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.05);
        -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.05);
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.05);
        tr {
            border-bottom-width: 0;
            &:nth-child(odd) {
                td {
                    background-color: $light-color;
                    border-color: $white-color;
                }
                th {
                    background-color: $black-color;
                }
            }
        }
        th,
        td {
            padding: size(15) size(15);
            vertical-align: top;
            border-top: none;
            border-right: 1px solid $light-color;
            border-bottom: none;
            &>* {
                margin: 0;
            }
            &:last-child {
                border-right: none;
            }
        }
        th {
            vertical-align: bottom;
            border-bottom: 1px solid $black-color;
            background-color: $secondary-color;
            color: $white-color;
            border-color: #3e3e3e !important;
            border: none !important;
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                margin: 0;
                color: $white-color;
            }
        }
        table {
            background-color: $white-color;
        }
        tbody+tbody {
            border-top: 2px solid $light-color;
        }
    }
    @include max(991.98) {
        overflow-y: hidden;
        overflow-x: auto;
        table {
            min-width: 500px;
        }
    }
    @include max(767.98) {
        overflow-y: hidden;
        overflow-x: auto;
        table {
            min-width: 480px;
        }
    }
}

.url_copy {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 10px;
    padding: 4px 10px;
    background: $pink-color;
    color: $white-color;
    border-radius: 10px;
    line-height: 1;
    bottom: size(-22);
}

.alert-info {
    color: #055160;
    background-color: #cff4fc;
    border-color: #b6effb;
}

::-moz-selection {
    /* Code for Firefox */
    color: $white-color;
    background: $primary-color;
}

::selection {
    color: $white-color;
    background: $primary-color;
}

.btn-see {
    border: none;
    background-color: transparent;
    display: inline-flex;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    transition: 0.3s all ease;
    span {
        width: 33px;
        height: 33px;
        border-radius: 50%;
        overflow: hidden;
        background-color: transparent;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 0;
        position: relative;
        z-index: 1;
        margin-left: 8px;
    }
    &-grey {
        span {
            background-color: $btn-grey-transparent;
            color: $black-color;
            transition: 0.3s all ease;
        }
        &:hover {
            color: $primary-color;
            span {
                background-color: $primary-color;
                color: $white-color;
            }
        }
        &-light {
            span {
                color: $white-color;
            }
        }
    }
}

body {
    font-family: 'Montserrat', sans-serif;
}


// text--animation-------------------

// @keyframes reveal {
//     from {
//       opacity: 0;
//       transform: translateY(100%);
//     }
  
//     to {
//       opacity: 1;
//       transform: translateY(0);
//     }
//   }

a {
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}
