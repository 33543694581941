@import "../../../styles/util";
.AboutUsTopicsWrapper {
    padding: size(150) 0;
    background-color: #FFF1DD;
    position: relative;
    @include max(767.98){
        padding: 50px 0;
        padding-bottom: 0;
    }
    &::before {
        content: " ";
        position: absolute;
        width: 100%;
        background-image: url(../../../assets/images/aboutus/topics/wave-top.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
        bottom: 99%;
        left: 0;
        height: 230px;
        z-index: 0;
        @include max(1300) {
            content: none;
        }
    }
    h2 {
        margin-bottom: size(60);
        text-align: center;
        color: #2E90D8;
        font-size: size(40);
        font-weight: 800;
        @include max(767.98){
           font-size: size(25);
        }
    }
    .topicListWrapper {
        display: flex;
        flex-wrap: wrap;
        margin-left: -45px;
        margin-right: -45px;
        .TopicsWrapper {
            padding: 0 45px;
            max-width: 33%;
            flex: 0 0 33%;
            margin-bottom: 70px;
            @include max(991.98){
                max-width: 50%;
                flex: 0 0 50%;
            }
            @include max(767.98){
                max-width: 90%;
                flex: 0 0 90%;
                margin-left: auto;
                margin-right: auto;
            }
            .TopicsCard {
                position: relative;
                z-index: 1;
                height: 100%;
                &::before {
                    content: " ";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    border: 5px dashed #F5B85B;
                    top: -25px;
                    left: -25px;
                    border-radius: 25px;
                    z-index: -1;
                    @include max(767.98){
                        top: -15px;
                        left: -15px;
                    }
                }
                .TopicsCardContent {
                    background-color: #FFFFFF;
                    border-radius: 25px;
                    padding: 40px;
                    text-align: center;
                    height: 100%;
                    box-shadow: 0px 6px 6px 0px rgb(0 0 0 / 16%);
                    @include max(767.98){
                        padding: 20px;
                    }
                    figure {
                        margin-bottom: 40px;
                    }
                    h4 {
                        font-size: size(25);
                        font-weight: 600;
                        color: #9D9D9D;
                        margin-bottom: 0;
                        @include max(767.98){
                            font-size: size(20);
                        }
                    }
                }
            }
        }
    }
}