@import "../../../../styles/util";
.LandingBenefitsSection {
    padding: 200px 0;
    background-color: #FFF8F9;
    position: relative;
    @include max(767.98) {
        padding: 50px 0;
    }
    h2 {
        max-width: 1332px;
        color: #FF8A3B;
        font-size: size(40);
        font-weight: 800;
        text-align: center;
        margin: auto;
        line-height: 1.3;
        margin-bottom: 40px;
        @include max(767.98) {
            font-size: size(25);
            margin-bottom: 20px;
        }
    }
    p{
        font-size: size(18);
        font-weight: 500;
        color: #363131;
        text-align: center;
        margin-bottom: 80px;
        line-height: 1.4;
        @include max(767.98) {
            font-size: size(16);
            margin-bottom: 20px;
        }
    }
    .cardWrap {
        display: flex;
        flex-wrap: wrap;
        .cards {
            max-width: 40%;
            flex: 0 0 40%;
            margin: 0 55px;
            padding: 25px;
            border-radius: 25px;
            background-color: #FFFFFF;
            -webkit-box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.16);
            -moz-box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.16);
            box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.16);
            margin-bottom: 60px;
            display: flex;
            z-index: 1;
            @include max(1199.98) {
                margin: 0 40px;
                margin-bottom: 40px;
            }
            @include max(991.98) {
                max-width: 100%;
                flex: 0 0 100%;
                margin: 0;
                margin-bottom: 30px;
            }
            @include max(767.98) {
                padding: 10px;
                margin-bottom: 20px;
            }
            .contentWrap {
                border-radius: 25px;
                border: 5px dashed #55CECE;
                padding: 25px;
                text-align: center;
                width: 100%;
                @include max(767.98) {
                    padding: 15px;
                }
                figure {
                    margin-bottom: 35px;
                    @include max(767.98) {
                        margin-bottom: 20px;
                    }
                }
                h3 {
                    font-size: size(30);
                    font-weight: 700;
                    color: #2E90D8;
                    margin-bottom: 20px;
                    @include max(767.98) {
                        font-size: size(20);
                    }
                }
                p {
                    max-width: 372px;
                    margin: auto;
                    font-size: size(18);
                    font-weight: 500;
                    color: #55CECE;
                    @include max(767.98) {
                        font-size: size(16);
                        line-height: 1.4;
                    }
                }
            }
        }
    }
    .leftBackground {
        position: absolute;
        left: 0;
        top: 20%;
        z-index: 0;
    }
    .rightBackground {
        position: absolute;
        right: 0;
        bottom: 20%;
        z-index: 0;
    }
    .blueWave {
        position: absolute;
        left: 10%;
        bottom: 10%;
        z-index: 0;
    }
    .yellowWave {
        position: absolute;
        right: 3%;
        top: 30%;
        z-index: 0;
    }
    .goldStar {
        position: absolute;
        left: 3%;
        bottom: 20%;
        z-index: 0;
    }
}