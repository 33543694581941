@import "../../../../styles/util";
.LandingSignupSection {
    background-color: #F5B85B;
    padding: 150px 0;
    position: relative;
    @include max(1600) {
        overflow: hidden;
    }
    @include max(767.98) {
        padding-top: 100px;
        padding-bottom: 0;
    }
    @include max(767.98) {
        padding-top: 50px;
    }
    &::before {
        content: " ";
        position: absolute;
        width: 100%;
        background-image: url(../../../../assets/images/landing/signup/wav-top.svg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
        bottom: 99%;
        left: 0;
        height: 130px;
        @include max(1300) {
            content: none;
        }
    }
    h2 {
        font-size: size(40);
        font-weight: 800;
        max-width: 730px;
        color: #FFFFFF;
        line-height: 1.4;
        margin-bottom: 20px;
        @include max(767.98) {
            font-size: size(25);
            text-align: center;
        }
    }
    .imgWrapper{
        max-width: 1000px;
        margin-left: auto;
        position: absolute;
        right: 0;
        bottom: 0;
        @include max(1700) {
            max-width: 1000px;
        }
        @include max(1600) {
            max-width: 700px;
        }
        @include max(1199.98) {
            max-width: 550px;
        }
        @include max(767.98) {
            position: relative;
            padding-bottom: 0;
            margin-top: 50px;
        }
        figure {
            margin-bottom: 0;
            @include aspectRatio(620, 400);
        }
    }
    .signupButtonWrapper {
        @include max(767.98) {
            text-align: center;
        }
    }
}