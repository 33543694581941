@import "../../../../styles/util";
.LandingAboutWrap {
    padding: 100px 0;
    position: relative;
    background-color: #FFF8F9;
    z-index: 1;
    @include max(767.98){
        padding-top: 375px;
        padding-bottom: 50px;
    }
    &::before {
        content: " ";
        position: absolute;
        width: 100%;
        background-image: url(../../../../assets/images/landing/about/wave-border.svg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
        bottom: 99%;
        left: 0;
        height: 145px;
        @include max(1300){
            content: none;
        }
    }
    .customRow {
        align-items: flex-end;
        .imageWrap {
            position: absolute;
            left: 0;
            padding-left: 0;
            @include max(1300){
                top:0;
            }
            @include max(767.98){
                max-width: 300px;
                left: 50%;
                transform: translateX(-50%);
            }
            figure {
                margin-bottom: 0;
                @include aspectRatio(859, 1035);
            }
        }
        .textWrap {
            padding-bottom: 200px;
            margin-left: auto;
            text-align: right;
            @include max(1300){
                padding-bottom: 0;
            }
            h2 {
                color: #2E90D8;
                max-width: 1060px;
                text-align: right;
                font-weight: 800;
                font-size: size(40);
                margin-left: auto;
                margin-bottom: size(50);
                line-height: 1.35;
                @include max(767.98){
                    text-align: left;
                    max-width: 100%;
                    font-size: size(25);
                    margin-bottom: 20px;
                }
            }
            p {
                text-align: right;
                font-size: size(18);
                font-weight: 500;
                line-height: 1.2;
                margin-bottom: size(30);
                max-width: size(911);
                margin-left: auto;
                @include max(767.98){
                    text-align: left;
                    max-width: 100%;
                    font-size: size(16);
                    margin-bottom: 20px;
                    line-height: 1.4;
                    margin-bottom: size(15);
                }
            }
            .LearnMoreAboutButton {
                max-width: size(700);
                margin-left: auto;
                // text-decoration: none;
                // display: flex;
                // justify-content: center;
                // text-align: center;
                // background-color: #FF8A3B;
                // color: #fff;
                // line-height: 1.2;
                // height: size(72);
                // align-items: center;
                // border-radius: 9px;
                // font-size: size(20);
                // font-weight: 500;
                // border: 1px solid #FF8A3B;
                // transition: 0.2s all ease-in-out;
                // &:hover {
                //     color: #FF8A3B;
                //     background-color: transparent;
                //     @include max(1400) {
                //         background-color: #FF8A3B;
                //         color: #fff;
                //     }
                // }
            }
        }
    }
}