@import "../../../../styles/util";
.ContactUsFormSection {
    background-color: #E2F0FA;
    padding: size(150) 0;
    position: relative;
    @include max(767.98){
        padding: size(50) 0;
        padding-bottom: 0;
    }
    &::before {
        content: " ";
        position: absolute;
        width: 100%;
        background-image: url(../../../../assets/images/contact/form/wave-top.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
        bottom: 99%;
        left: 0;
        height: 220px;
        z-index: 0;
        @include max(1300) {
            content: none;
        }
    }
    .FormWrap {
        .formContentWrapper {
            box-shadow: 0px 6px 6px 0px rgb(0 0 0 / 16%);
            padding: 80px;
            background-color: #FFFFFF;
            border-radius: 25px;
            @include max(1200){
                padding: 40px;
            }
            @include max(767.98){
                padding: size(20);
            }
            h3 {
                color: #363131;
                font-size: size(30);
                font-weight: 600;
                margin-bottom: 50px;
                @include max(767.98){
                    font-size: size(20);
                    margin-bottom: 30px;
                }
            }
            input {
                outline: none;
                margin-bottom: 50px;
                border-color: #CECECE;
                border-radius: 9px;
                &:not(:placeholder-shown) {
                    label {
                        transform: scale(0.5);
                    }
                }
            }
            label {
                color: #9D9D9D;
                font-size: size(20);
                font-weight: 500;
            }
            textarea {
                outline: none;
                resize: none;
                height: 170px;
                &:focus {
                    border-color: #CECECE;
                }
                margin-bottom: 50px;
            }
            .submitBtn {
                width: 100%;
            }
        }
    }
    .ContentWrap {
        padding-left: 60px;
        @include max(767.98){
            padding-left: 15px;
            margin-top: 25px;
        }
        h2 {
            color: #2E90D8;
            font-size: size(40);
            font-weight: 700;
            margin-bottom: 30px;
            @include max(767.98){
                font-size: size(25);
            }
        }
        P {
            font-size: size(16);
            font-weight: 500;
            color: #383333;
            line-height: 1.5;
            margin-bottom: 0;
            @include max(767.98){
                font-size: size(16);
            }
        }
        .FormFigure {
            margin-bottom: 0;
            position: absolute;
            right: 0;
            bottom: 0;
            @include max(1800){
                max-width: 700px;
            }
            @include max(1400){
                position: relative;
            }
            @include max(767.98){
                margin-top: 15px;
            }
        }
    }
}

:global {
    .form-floating>.form-control:focus~label,
    .form-floating>.form-control:not(:placeholder-shown)~label,
    .form-floating>.form-control-plaintext~label,
    .form-floating>.form-select~label {
        transform: scale(0.60) translateY(-0.5rem) translateX(0.15rem) !important;
    }
}

.inputError,
.react-datepicker__input-container .inputError {
    border: 1px solid red !important;
}

.errorMessage {
    border: red;
    color: red;
    font-size: 11px;
    position: relative;
    top: -45px;
}
  