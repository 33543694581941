@import "../../../../styles/util";
.HomePageFeatureSection {
    padding: size(150) 0;
    background-color: #FFF8F9;
    position: relative;
    z-index: 1;
    @include max(767.98){
        padding: size(50) 0;
        padding-bottom: 10px;
    }
    &::before {
        content: " ";
        position: absolute;
        width: 100%;
        background-image: url(../../../../assets/images/home/features/border-wave.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
        bottom: 99%;
        left: 0;
        height: 150px;
        z-index: 6;
        @include max(1300) {
            content: none;
        }
    }
    h2 {
        font-size: size(40);
        color: #F5B85B;
        font-weight: 800;
        text-align: center;
        line-height: 1.2;
        margin-bottom: 20px;
        @include max(576.98){
            font-size: size(25);
        }
    }
    p {
        color: #363131;
        text-align: center;
        font-size: size(16);
        font-weight: 500;
        line-height: 1.5;
        max-width: 1534px;
        margin: auto;
        margin-bottom: 60px;
        @include max(576.98){
            font-size: size(16);
            margin-bottom: 20px;
        }
    }
    .cardRow {
        display: flex;
        flex-wrap: wrap;
        margin-left: -40px;
        margin-right: -40px;
        .cardWrapper {
            padding: 0 40px;
            max-width: 33%;
            flex: 0 0 33%;
            margin-bottom: 40px;
            @include max(1200){
                max-width: 50%;
                flex:0 0 50%;
            }
            @include max(767.98){
                max-width: 100%;
                flex:0 0 100%;
            }
            .cardContent {
                padding: 25px;
                background-color: #FFFFFF;
                border-radius: 25px;
                box-shadow: 0px 6px 6px 0px rgb(0 0 0 / 16%);
                height: 100%;
                @include max(576.98){
                    padding: 15px;
                }
                .cardBody {
                    border-radius: 25px;
                    border: 5px dashed #55CECE;
                    padding: 40px 25px 25px 25px;
                    height: 100%;
                    @include max(576.98){
                        padding: 15px;
                    }
                    figure {
                        margin-bottom: 25px;
                        text-align: center;
                        @include max(576.98){
                            margin-bottom: 15px;
                        }
                    }
                    h4 {
                        font-size: size(30);
                        font-weight: 700;
                        color: #2E90D8;
                        margin-bottom: 20px;
                        text-align: center;
                        @include max(576.98){
                            font-size: size(22);
                        }
                    }
                    p {
                        margin-bottom: 0;
                        font-size: size(16);
                        color: #55CECE;
                        line-height: 1.5;
                        @include max(576.98){
                            font-size: size(16);
                        }
                    }
                }
            }
        }
        .BackgroundRing {
            position: absolute;
            left: 0;
            top: 22%;
            margin-bottom: 0;
            z-index: -1;
        }
        .BackgroundStar {
            position: absolute;
            right: 0;
            bottom: 20%;
            margin-bottom: 0;
            z-index: -1;
        }
    }
}
